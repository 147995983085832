import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from 'react-redux';
import { userSelector } from "redux/userSlice";
import { selectResourceTypeGroupsConf, selectResourcesTypesConf } from "redux/configurationSlice";
import { Box, Card, CardHeader, CardContent, Tabs, Tab, useMediaQuery, Autocomplete, TextField, MenuItem, Menu, Typography, Stack, Tooltip, Button, IconButton } from "@mui/material";
import { ListOutlined, RefreshOutlined } from '@mui/icons-material';
import { MeasurementKPIs, LoadingData, AlertSnackbar, ComplianceTag } from 'components';
import API from 'api';


export default function LatestMeasurementsTabs(props) {
    const { location } = props;
    const { token } = useSelector(userSelector);
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const [tabValue, setTabValue] = useState(0);
    const [groups, setGroups] = useState([]);
    const [filterName, setFilterName] = useState(null);
    const [values, setValues] = useState([]);
    const [groupResources, setGroupResources] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [caseSensitive, setCaseSensitive] = useState(false);
    const resTypeGroups = useSelector(selectResourceTypeGroupsConf);
    const resourceTypesConf = useSelector(selectResourcesTypesConf);
    const [alert, setAlert] = useState({ open: false });
    const [showComplianceTag, setShowComplianceTag] = useState(false);
    const [reset, setReset] = useState(0);
    const [disableRefresh, setDisableRefresh] = useState(true);

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        const values = [];
        resTypeGroups.forEach(group => {
            if (location.resourceTypes.filter(type => group.resourceTypes.includes(type)).length) values.push(group.name);
        });
        if (location.serviceResourceTypes.length) values.push("SERVICE");
        setGroups(values);
    }, [location, resTypeGroups, setGroups]);

    useEffect(() => {
        const currentGroup = groups[tabValue];
        if (currentGroup === "SERVICE" || currentGroup === "SENSOR") setShowComplianceTag(false);
        else setShowComplianceTag(true);
    }, [groups, tabValue]);

    const renderTabs = useCallback(() => {
        const handleChange = (e, index) => {
            setFilterName(null);
            setTabValue(index);
        }
        return <Tabs
            variant="scrollable"
            scrollButtons
            value={tabValue}
            onChange={handleChange}
        >
            {groups.map(el => <Tab wrapped key={el} name={el} label={<FormattedMessage id={"RESOURCE_TYPE.GROUP." + el} />} />)}
        </Tabs>;

    }, [groups, tabValue, setTabValue, setFilterName]);

    const renderMenu = useCallback(() => {
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleChange = (index) => {
            setFilterName(null);
            setTabValue(index);
            handleClose();
        };

        return (
            <>
                <IconButton onClick={handleClick} color='primary'>
                    <ListOutlined />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    {groups.map((el, index) => (
                        <MenuItem key={el} onClick={() => handleChange(index)}>
                            <Typography color={tabValue === index ? "primary" : "inherit"}>
                                <FormattedMessage id={"RESOURCE_TYPE.GROUP." + el} />
                            </Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    }, [groups, tabValue, setTabValue, anchorEl, setAnchorEl]);

    useEffect(() => {
        if (!groups.length || values.length) { setDisableRefresh(!groups.length); return; }

        let resourceTypes;
        if (groups[tabValue] === "ALL") resourceTypes = [];
        else if (groups[tabValue] === "SERVICE") resourceTypes = location.serviceResourceTypes;
        else resourceTypes = location.resourceTypes.filter(type => {
            const filteredType = resTypeGroups.find(group => group.name === groups[tabValue]);
            if (filteredType) return filteredType.resourceTypes.includes(type);
            else return false;
        });
        setGroupResources(resourceTypes);


        setLoading(true);
        API.measurements.getLocationLatest(token, [location._id], resourceTypes).then((items) => {
            if (items && items.data && items.data.length) {
                setValues(items.data.find(data => data.locationId === location._id).values.sort((a, b) => {
                    const resourceTypeA = resourceTypesConf.find(el => el.key === a.resourceKey);
                    const resourceTypeB = resourceTypesConf.find(el => el.key === b.resourceKey);
                    const nameA = intl.formatMessage({ id: resourceTypeA.name });
                    const nameB = intl.formatMessage({ id: resourceTypeB.name });
                    if (nameA.toUpperCase() < nameB.toUpperCase()) {
                        return -1;
                    }
                    if (nameA.toUpperCase() > nameB.toUpperCase()) {
                        return 1;
                    }
                    return 0;
                })
                );
            }
            else setValues([]);
            setLoading(false);
            setDisableRefresh(false);
        }).catch((error) => {
            console.error("Error fetching data, reason: ", error);
            setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
            setLoading(false);
            setDisableRefresh(false);
        });
    }, [token, groups, tabValue, location, setValues, resTypeGroups, intl, resourceTypesConf, values.length, reset]);

    const renderKPIs = useCallback(() => {
        if (loading) return <LoadingData noText size={3} />;
        else if (values.length) return <MeasurementKPIs values={values} filter={{ text: filterName, caseSensitive: caseSensitive }} />;
        else return <Typography color="text.secondary"><FormattedMessage id="NO_DATA" /></Typography>;
    }, [loading, values, filterName, caseSensitive]);

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.messageId} />
        <Card sx={{ width: '100%' }}>
            <CardHeader
                title={<div>
                    <span><FormattedMessage id="LATEST_MEASUREMENTS" /> <Tooltip title={<FormattedMessage id="REFRESH" />} placement="right">
                        <IconButton component="span" disabled={disableRefresh} size="small" onClick={() => { setReset(reset + 1); setValues([]); setDisableRefresh(true); }}><RefreshOutlined /></IconButton>
                    </Tooltip></span>
                    <br />
                    {showComplianceTag && <ComplianceTag locationId={location._id} />}
                </div>}
                action={smallScreen ?
                    renderMenu()
                    : <Box sx={{ maxWidth: '400px' }}>
                        {renderTabs()}
                    </Box>
                } />
            {groupResources.length > 8 &&
                <Stack direction="row" sx={{ px: 1.5 }} spacing={0.5}>
                    <Autocomplete
                        sx={{ pb: 2 }}
                        freeSolo
                        size="small"
                        fullWidth
                        id="resource-select"
                        options={resourceTypesConf.filter(el => groupResources.includes(el.key)).map(el => intl.formatMessage({ id: el.name }))}
                        value={filterName}
                        onChange={(e, value) => setFilterName(value)}
                        renderInput={(params) => (
                            <TextField {...params} label={intl.formatMessage({ id: "SEARCH_BY.RESOURCE_NAME" + (caseSensitive ? ".MATCH_CASE" : "") })} />
                        )}
                    />
                    <Tooltip title={<FormattedMessage id="MATCH_CASE" />}>
                        <Button sx={{ textTransform: 'none', height: '40px', px: 2 }} variant={caseSensitive ? "contained" : "outlined"} color="primary" onClick={() => setCaseSensitive(!caseSensitive)}>
                            Aa
                        </Button>
                    </Tooltip>
                </Stack>
            }
            <CardContent>
                {renderKPIs()}
            </CardContent>
        </Card >
    </>
}