import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { selectLocationDetails } from 'redux/locationsSlice'
import { userSelector, permissionSelector } from "redux/userSlice";
import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { IncidentReportsView, LoginRequired } from "components";

function IncidentReportsPage(props) {
    const [searchparams] = useSearchParams();
    const locationId = searchparams.get("locationId");
    const location = useSelector((state) => selectLocationDetails(state, locationId));

    if (!locationId) return <EmptyState component={<IncidentReportsView />} />;
    else if (location) return <EmptyState component={<IncidentReportsView location={location} />} />;
    else return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;
}

export default function IncidentReportsPageComponent(props) {
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const viewReportPermission = useSelector((state) => permissionSelector(state, 'view-report'));


    if (loggedIn && viewReportPermission) return <DefaultLayout page={<IncidentReportsPage />} />;
    else if (loggedIn) return <DefaultLayout page={<EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />} />;
    else return <PublicLayout page={<LoginRequired />} />;
};


