import { Typography, Grid, Card, CardHeader, CardContent, List, ListItem, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useIntl } from 'react-intl';

function ParagraphTitle({ text }) { return <Typography color="primary" component="span" variant="h6">{text}</Typography> };
function ParagraphBody({ children }) { return <Typography variant="body1" sx={{ mb: 1.3 }} >{children}</Typography> }

export default function AboutView(props) {
    const app = useSelector((state) => state.app);
    const theme = useTheme().palette.mode;
    const intl = useIntl();

    return <Card>
        <CardHeader title={<Typography variant="h5">{intl.formatMessage({ id: "ABOUT_PAGE.TITLE" })}</Typography>} />
        <CardContent>
            <ParagraphTitle text={intl.formatMessage({ id: "ABOUT_PAGE.PROJECT_OBJECTIVES" })} />
            <ParagraphBody>{intl.formatMessage({ id: "ABOUT_PAGE.PROJECT_OBJECTIVES.BODY" })}</ParagraphBody>

            <ParagraphTitle text={intl.formatMessage({ id: "ABOUT_PAGE.ACTIVITIES" })} />
            <ParagraphBody>{intl.formatMessage({ id: "ABOUT_PAGE.ACTIVITIES.BODY" })}</ParagraphBody>

            <ParagraphTitle text={intl.formatMessage({ id: "ABOUT_PAGE.EXPECTED_RESULTS" })} />
            <ParagraphBody>
                {intl.formatMessage({ id: "ABOUT_PAGE.EXPECTED_RESULTS.BODY" })}<br />
                <Typography variant="subtitle1" component="span">
                    <List component="span">
                        <ListItem>
                            {intl.formatMessage({ id: "ABOUT_PAGE.EXPECTED_RESULTS.BODY.ITEM1" })}
                        </ListItem>
                        <ListItem>
                            {intl.formatMessage({ id: "ABOUT_PAGE.EXPECTED_RESULTS.BODY.ITEM2" })}
                        </ListItem>
                        <ListItem>
                            {intl.formatMessage({ id: "ABOUT_PAGE.EXPECTED_RESULTS.BODY.ITEM3" })}
                        </ListItem>
                    </List>
                </Typography>
            </ParagraphBody>

            <ParagraphBody>{intl.formatMessage({ id: "ABOUT_PAGE.EXPECTED_RESULTS.BODY2" })}</ParagraphBody>
            {app.lang === "hr" || app.lang === "rs" || app.lang === "si" ? <Grid container spacing={1} direction="row" alignItems="center">
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <img src={window.location.origin + `/assets/${theme}_Erasmus_EU_hr.png`} alt='erasmus' height={100} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <img src={window.location.origin + `/assets/${theme}_hr_Co-funded_EU.png`} alt='eu' height={55} width={200} />
                </Grid>
            </Grid> : <Grid container spacing={1} direction="row" alignItems="center">
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <img src={window.location.origin + `/assets/${theme}_Erasmus_EU_${app.lang}.png`} alt='erasmus' height={100} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <img src={window.location.origin + `/assets/${theme}_${app.lang}_Co-funded_EU.png`} alt='eu' height={55} width={app.lang === 'en' ? 250 : 200} />
                </Grid>
            </Grid>}
        </CardContent>
    </Card>;
}