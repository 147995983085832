import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from 'react-redux';
import { selectResourceTypeGroupsConf, selectAttractionCategoryTypesConf, selectTourismTypesConf, selectAccessibilityOptionsConf } from "redux/configurationSlice";
import { updateLocationStateDetails } from 'redux/locationsSlice';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { MeasurementsTrendGraph, DateRangeSelector, ReportingButtons, OWAttribution, AlertSnackbar, SideDrawer, LocationAlbumGallery } from "components";
import { Avatar, Tooltip, Fab, Chip, Card, CardContent, CardMedia, CardActions, CardHeader, Grid, Typography, useMediaQuery, Button } from "@mui/material";
import { AccessibleOutlined, Castle, Forest, QuestionMark, ImageOutlined, PlaceOutlined, ElderlyOutlined, DirectionsBusOutlined, DirectionsCarOutlined, DirectionsBikeOutlined, DirectionsWalkOutlined, InfoOutlined, CollectionsOutlined, TimelineOutlined } from "@mui/icons-material";
import { LocationMapTab, LatestMeasurementsTabs, /*LoginRequired, SamplingLogsSamplesCount, SamplingLogTypeDistributionPie, SamplingLogQualityDistributionPie */ } from 'components';
import API from 'api';


function PublicDetailsView(props) {
    const { locationEntity, publicView, showGallery, setShowGallery } = props;
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const attractionTypes = useSelector(selectAttractionCategoryTypesConf);
    const tourismTypes = useSelector(selectTourismTypesConf);
    const accessibilityOptions = useSelector(selectAccessibilityOptionsConf);
    const locale = useIntl().locale;
    const [mode, setMode] = useState("map");
    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState({});
    const [refreshData, setRefreshData] = useState(false);


    const handleModeChange = () => {
        if (mode === "map") setMode("img");
        else setMode("map");
    }

    const renderIcon = (pointType) => {
        switch (pointType) {
            case 2:
                return <Castle />;
            case 1:
                return <Forest />;
            case 0:
            default:
                return <QuestionMark />;
        }
    };

    const getColor = (attractionType, theme) => {
        switch (attractionType) {
            case 2:
                return theme.palette.culturalHeritage.main;
            case 1:
                return theme.palette.success.main;
            case 0:
            default:
                return theme.palette.text.disabled;
        }
    };

    const renderAccessibilityIcons = (option) => {
        switch (option) {
            case 5:
                return <ElderlyOutlined color="success" />;
            case 4:
                return <DirectionsBusOutlined color="success" />;
            case 3:
                return <DirectionsCarOutlined color="success" />;
            case 2:
                return <DirectionsBikeOutlined color="success" />;
            case 1:
                return <DirectionsWalkOutlined color="success" />;
            case 0:
                return <AccessibleOutlined color="success" />;
            default:
                return null
        }
    }

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const attractionType = locationEntity.touristAttraction?.attractionType;
    const tourismTypeArray = locationEntity.touristAttraction?.tourismTypes;
    const accessibilityOptionsArray = locationEntity.touristAttraction?.accessibilityOptions;

    useEffect(() => {
        const info = locationEntity.touristAttraction?.additionalInfo
        setDetails({
            title: <FormattedMessage id='ADDITIONAL_INFO' />,
            subtitle: locationEntity.name,
            display: info && info.length ? info : <Typography color="text.disabled"><FormattedMessage id='NO_DATA' /></Typography>
        });
    }, [locationEntity.name, locationEntity.touristAttraction?.additionalInfo]);

    useEffect(() => {
        return () => setRefreshData(false);
    })

    return <>
        <SideDrawer open={open} state={details} toggleDrawer={toggleDrawer} />
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex' }}>
            <Card sx={{ width: '100%' }}>
                <CardHeader
                    action={
                        accessibilityOptionsArray?.length ?
                            accessibilityOptionsArray.map(el => (
                                <Tooltip title={<FormattedMessage id={"ACCESSIBILITY_OPTIONS." + accessibilityOptions.find(type => type.key === el).value} />} placement="bottom" arrow key={el}>
                                    {renderAccessibilityIcons(el)}
                                </Tooltip>
                            )) : null
                    }
                    avatar={<Avatar sx={theme => ({ bgcolor: getColor(attractionType, theme) })}>{renderIcon(attractionType)}</Avatar>}
                    title={<Typography variant="h5">{locationEntity.name}</Typography>}
                    subheader={locationEntity.submitted !== undefined && locationEntity.submitted === false ? <Typography color="error.main"><FormattedMessage id="STAGING.PENDING.SUBMIT" /></Typography>
                        : locationEntity.addedBy ? <Typography color={!locationEntity.reviewedBy ? "warning.main" : "info.main"}><FormattedMessage id={"STAGING.PENDING." + (!locationEntity.reviewedBy ? "REVIEW" : "APPROVE")} /></Typography>
                            : attractionType && attractionType !== undefined ? <FormattedMessage id={"ATTRACTION." + attractionTypes.find(el => el.key === attractionType)?.value} />
                                : null
                    }
                />

                <CardContent>{locationEntity.description && locationEntity.description[locale] && locationEntity.description[locale]?.length ? locationEntity.description[locale] : <Typography color="text.disabled"><FormattedMessage id="NO_DESCRIPTION" /></Typography>}</CardContent>

                {tourismTypeArray?.length ? <CardContent>
                    {tourismTypeArray?.map(type => <Chip sx={{ mr: 1, mb: 1 }} color="primary" variant="outlined" key={"type-" + type} label={<FormattedMessage id={"TOURISM." + tourismTypes.find(ttp => ttp.key === type)?.value} />} />)}
                </CardContent> : null}
            </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex' }}>
            <div style={{ position: 'relative' }}>
                <Tooltip title={<FormattedMessage id={"DETAILS.SWITCH_" + mode.toUpperCase()} />} arrow placement="right">
                    <Fab sx={{ position: 'absolute', top: 8, left: 8 }} size="small" onClick={handleModeChange}>
                        {mode === "map" ? <ImageOutlined /> : <PlaceOutlined />}
                    </Fab>
                </Tooltip>
            </div>
            <Card sx={{ width: '100%' }}>
                {mode === "map"
                    ? <LocationMapTab location={locationEntity} />
                    : <CardMedia
                        component="img"
                        image={process.env.REACT_APP_API_BASE_URL + "/" + locationEntity.picture}
                        alt="location-picture"
                    />
                }
            </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Card>
                <CardActions>
                    <Button
                        sx={{ mx: 'auto' }}
                        size="small"
                        startIcon={<InfoOutlined />}
                        onClick={toggleDrawer}
                        name='additionalInfo' key="additionalInfo"
                    >
                        <FormattedMessage id="ADDITIONAL_INFO" />
                    </Button>
                </CardActions>
            </Card>
        </Grid>
        {!publicView && <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
            <Card>
                <CardActions>
                    <ReportingButtons location={locationEntity} locationId={locationEntity._id} column={smallScreen} setRefreshData={() => { setRefreshData(true); setShowGallery(true) }} />
                </CardActions>
            </Card>
        </Grid>}
        {showGallery && <LocationAlbumGallery location={locationEntity} refreshData={refreshData} setRefreshData={(data) => setRefreshData(data)} />}
    </>
}

export default function LocationDetailsView(props) {
    //TODO: more work needed
    const { location, locationId, publicView } = props;
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const resTypeGroups = useSelector(selectResourceTypeGroupsConf);
    const [locationEntity, setLocationEntity] = useState(location || undefined);
    const { token } = useSelector(userSelector);
    const [showGallery, setShowGallery] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const dispatch = useDispatch();
    const viewMeasurementsPermission = useSelector((state) => permissionSelector(state, 'view-measurements'));
    const viewAlbumsPermission = useSelector((state) => permissionSelector(state, 'view-album'));

    useEffect(() => {
        if (!viewMeasurementsPermission && viewAlbumsPermission) setShowGallery(true);
        else setShowGallery(false);
    }, [viewMeasurementsPermission, viewAlbumsPermission]);

    useEffect(() => {
        if (locationId && location === undefined) API.locations.getLocationById(token, locationId).then(data => {
            dispatch(updateLocationStateDetails(data.data));
            setLocationEntity(data.data);
        }).catch(e => {
            console.error("Error fetching location, ", e);
            setAlert({ open: true, message: e.data, severity: "error" });
        })
    }, [dispatch, token, location, locationId]);


    const onAlertClose = () => setAlert({ ...alert, open: false });
    if (locationEntity === undefined) return <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.message} />;
    else {
        const gridItems = resTypeGroups.map((group) => {
            if (!publicView) return {
                title: "RESOURCE_TYPE.GROUP." + group.name,
                resourceTypes: group.name === "SERVICE" ?   // if SERVICE type group
                    locationEntity.serviceResourceTypes           // get locationServiceTypes
                    : locationEntity.resourceTypes.filter(res => group.resourceTypes.includes(res)), // else get otherResTypes
            };
            else if (publicView && (group.name !== "SERVICE" && group.name !== "SENSOR")) return {
                title: "RESOURCE_TYPE.GROUP." + group.name,
                resourceTypes: locationEntity.resourceTypes.filter(res => group.resourceTypes.includes(res))
            }
            else return null;
        }).filter(el => el !== null);

        return (
            <Grid container direction="column" spacing={2}>
                {viewMeasurementsPermission && viewAlbumsPermission && <Tooltip title={showGallery ? "Show data in graphs" : "Show photo albums"} placement='top-start'>
                    <Fab
                        component="span"
                        color="primary"
                        sx={{ position: 'absolute', bottom: 16, right: 32 }}
                        onClick={() => setShowGallery(!showGallery)}
                    >{showGallery ? <TimelineOutlined /> : <CollectionsOutlined />}</Fab>
                </Tooltip>}
                <Grid item container direction="row" spacing={2}>
                    <PublicDetailsView locationEntity={locationEntity} publicView={publicView} showGallery={showGallery} setShowGallery={setShowGallery} />
                    {!showGallery && <>
                        {!publicView && viewMeasurementsPermission && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex' }}>
                            <LatestMeasurementsTabs location={locationEntity} />
                        </Grid>}
                        {!!gridItems.flatMap(item => item.resourceTypes).length && !publicView && viewMeasurementsPermission && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex' }}>
                            <Card sx={{ width: '100%' }}>
                                <CardContent >
                                    <DateRangeSelector column={smallScreen} />
                                </CardContent>
                            </Card>
                        </Grid>}
                    </>}
                </Grid>
                {!publicView && !showGallery && viewMeasurementsPermission && <Grid item container direction="row" spacing={2}>
                    {gridItems.map(item => (
                        item.resourceTypes.length ?
                            <Grid key={item.title} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <MeasurementsTrendGraph
                                    title={<FormattedMessage id={item.title} />}
                                    attribution={item.title.includes("SERVICE") ? <OWAttribution /> : undefined}
                                    locationId={locationEntity._id}
                                    resourceTypes={item.resourceTypes}
                                    type={item.title?.includes("SERVICE") ? "service" : item.title?.includes("SENSOR") ? "sensor" : "manual"}
                                />
                            </Grid> : null
                    ))}
                </Grid>}
            </Grid>
        );
    }
}