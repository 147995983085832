import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { selectMeasurePoints } from 'redux/locationsSlice';
import { userSelector } from 'redux/userSlice';
import { selectPermissionsByKey, selectStagingSamplesFeature, selectImportResourceTypes, selectResourcesTypesConf } from 'redux/configurationSlice';
import { AddSampleHistoryForm, AddSampleHistoryReport, CustomCardReport, AlertSnackbar, EditSampleForm } from "components";
import { exportData } from 'utils';

import { Alert, Autocomplete, Button, TextField, Badge, Tooltip, IconButton, Pagination, Stack, Typography } from '@mui/material';
import { NoteAddOutlined, FileDownloadOutlined, OpenInNewOutlined, EditOutlined } from '@mui/icons-material';

import API from 'api';


export default function AddSampleHistoryView(props) {
    const { location, editFlag } = props;
    const intl = useIntl();
    const navigate = useNavigate();
    const locations = useSelector(selectMeasurePoints);
    const importResourceTypes = useSelector(selectImportResourceTypes);
    const resourcesTypesConf = useSelector(selectResourcesTypesConf);
    const user = useSelector(userSelector);
    const [file, setFile] = useState(null);
    const [skipErrors, setSkipErrors] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(location ? location : null);
    const [reportData, setReportData] = useState(null);
    const [page, setPage] = useState(1);
    const [alert, setAlert] = useState({ open: false });
    const stagingFeature = useSelector(selectStagingSamplesFeature);
    const permissionList = useSelector(selectPermissionsByKey);
    const createStagingSample = permissionList['create-staging-sample'] ? true : false;
    const reviewStagingSample = permissionList['review-staging-sample'] ? true : false;
    const createSample = permissionList['create-sample'] ? true : false;
    const updateSample = permissionList['update-sample']?.includes(reportData?.samplingLogsArray[page - 1]?.locationId)
    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (editFlag !== null && reportData === null) navigate('/sampleEntry/history')
    }, [editFlag, reportData, navigate]);

    const render = useCallback(() => {

        const handleReset = () => {
            setReportData(null);
            setSelectedLocation(null);
            setFile(null);
            setPage(1);
            setSkipErrors(false);
        }

        const handleDownloadClick = event => {
            const tableHeaders = [intl.formatMessage({ id: "TIMESTAMP" })];
            selectedLocation.resourceTypes.filter(el => importResourceTypes.includes(el)).forEach(el => {
                const res = resourcesTypesConf.find(rt => rt.key === el);
                const typeWithUnit = intl.formatMessage({ id: res.name }).replaceAll(",", " ").concat(` [${res.unit}]`)
                if (res) tableHeaders.push(typeWithUnit);
            });
            tableHeaders.push(intl.formatMessage({ id: "NOTES" }));
            tableHeaders.push(intl.formatMessage({ id: "SAMPLE_MARK_OF_HZJZ" }));
            tableHeaders.push(intl.formatMessage({ id: "SAMPLE_MARK_OF_SUPPLIER" }));
            exportData({ getCsvData: true }, selectedLocation.name, intl.formatMessage({ id: "TEMPLATE" }), [], tableHeaders, undefined, false);
        }

        const handleUploadClick = event => {
            if (stagingFeature) {
                if (createSample) {
                    API.samplingLogs.postHistorySamples(user.token, [selectedLocation._id], user.email, file, skipErrors)
                        .then(({ data, status }) => {
                            setReportData(data);
                            setPage(1);
                            setSkipErrors(false);
                        }).catch(error => {
                            console.error("An error has occured: ", error);
                            setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                        });
                }
                else if (createStagingSample && !reviewStagingSample) {
                    API.samplingLogs.postHistoryStagingSamples(user.token, [selectedLocation._id], user.email, file, skipErrors)
                        .then(({ data, status }) => {
                            setReportData(data);
                            setPage(1);
                            setSkipErrors(false);
                        }).catch(error => {
                            console.error("An error has occured: ", error);
                            setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                        });
                }
                else if (createStagingSample && reviewStagingSample) {
                    API.samplingLogs.postHistoryStagingSamplesReview(user.token, [selectedLocation._id], user.email, file, skipErrors)
                        .then(({ data, status }) => {
                            setReportData(data);
                            setPage(1);
                            setSkipErrors(false);
                        }).catch(error => {
                            console.error("An error has occured: ", error);
                            setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                        });
                }
            }

            else {
                API.samplingLogs.postHistorySamples(user.token, [selectedLocation._id], user.email, file, skipErrors)
                    .then(({ data, status }) => {
                        setReportData(data);
                        setPage(1);
                        setSkipErrors(false);
                    }).catch(error => {
                        console.error("An error has occured: ", error);
                        setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                    });
            }
        }

        const handleExportRows = () => {
            const rows = [];
            reportData.errorArrays.array.forEach(el => {
                const row = [];
                reportData.headers.forEach(header => {
                    if (el[header]) row.push(el[header]);
                    else row.push(" ");
                })
                rows.push(row);
            });
            exportData({ getCsvData: true }, selectedLocation.name, intl.formatMessage({ id: "INVALID_ROWS" }), rows, reportData.headers, undefined, false)
        }

        if (reportData) return <CustomCardReport
            title={<FormattedMessage id="REPORT" />}
            subtitle={!!reportData.errorArrays.number &&
                <Badge
                    showZero max={99}
                    badgeContent={reportData.errorArrays.number}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    color="error"
                >
                    <Button startIcon={<FileDownloadOutlined />} onClick={handleExportRows}><FormattedMessage id="DOWNLOAD_INVALID_ROWS" /></Button>
                </Badge>
            }
            headerAction={[
                <Tooltip title={<FormattedMessage id={"FORM.ADD_SAMPLE"} />} key="addSample" placement="top-start" arrow>
                    <IconButton name="addSampleButton" onClick={handleReset}>
                        <NoteAddOutlined />
                    </IconButton>
                </Tooltip>,
                (editFlag === null && updateSample && <Tooltip title={<FormattedMessage id={"FORM.EDIT_SAMPLE"} />} key="editSample" placement="top-start" arrow>
                    <IconButton name="editSampleButton" onClick={() => navigate('?edit')}>
                        <EditOutlined />
                    </IconButton>
                </Tooltip>),
                <Tooltip title={<FormattedMessage id={"VIEW_LOCATION"} />} key="locationDetails" placement="top-start" arrow>
                    <IconButton name="locationDetailsButton" onClick={() => navigate('/locations/details/' + selectedLocation._id)}>
                        <OpenInNewOutlined />
                    </IconButton>
                </Tooltip>
            ]}
            actions={!!reportData.samplingLogsArray.length && <Pagination sx={{ position: 'absolute', bottom: '0%', my: 2 }} count={reportData.samplingLogsArray.length} page={page} onChange={(e, p) => setPage(p)} />}
        >
            {editFlag !== null ? <EditSampleForm location={location} historyReportData={reportData} historyPage={page - 1} existingSamplingLog={reportData.samplingLogsArray[page - 1]} history setReportData={setReportData} setAlert={setAlert}
            /> : <AddSampleHistoryReport reportData={reportData} page={page} />}
        </CustomCardReport>

        else return <CustomCardReport
            title={<FormattedMessage id="ADD_SAMPLE_FILE" />}
            avatar={<NoteAddOutlined fontSize='small' color='primary' />}
            subtitle={<Stack direction="column" spacing={1}>
                <Autocomplete
                    size="small"
                    id="single-select"
                    options={locations}
                    value={selectedLocation}
                    onChange={(e, location) => setSelectedLocation(location)}
                    getOptionLabel={(location) => location.name}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                        <TextField {...params} key={locations.map(location => location._id)} label={<FormattedMessage id="ALL_LOCATIONS" />} />
                    )}
                />
                {selectedLocation !== null && !selectedLocation.resourceTypes.filter(el => importResourceTypes.includes(el)).length && <Alert severity="warning"><FormattedMessage id="NO_MANUAL_TYPES" /></Alert>}
                <Typography variant='caption' color="text.secondary" sx={{ pt: 1 }} ><FormattedMessage id="TIMESTAMP.FORMAT_NOTE" /></Typography>
            </Stack>
            }
        >

            <AddSampleHistoryForm
                selectedLocation={selectedLocation}
                file={file}
                setFile={setFile}
                skipError={skipErrors}
                changeSkipError={() => setSkipErrors(!skipErrors)}
                handleDownloadClick={handleDownloadClick}
                handleUploadClick={handleUploadClick}
            />
        </CustomCardReport>;

    }, [user, locations, selectedLocation, setSelectedLocation, file, setFile, reportData, importResourceTypes, intl, resourcesTypesConf, page, setPage, skipErrors, setSkipErrors, navigate, stagingFeature, createSample, reviewStagingSample, createStagingSample, editFlag, location, updateSample])

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.message || alert.messageId} />
        {render()}
    </>
}