import React, { useState } from 'react';
import { Button, Card, Container, Dialog, DialogContent, DialogTitle, Divider, Stack, Typography, useMediaQuery, useTheme, CardHeader, CardContent } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { PictureGallery, EditSampleForm, ComplianceTag } from 'components';
import { useSelector } from 'react-redux';
import { selectResourceTypeGroupsConf, selectPermissionsByKey } from 'redux/configurationSlice';
import { selectFontWeight } from 'redux/appSlice';

function SamplingLogDetails(props) {
    const { timestamp, locationName, locationId, logId, sampleType, values, notes, pictures, collector, staging, reviewedBy, labels, timestampForDialog, toggleDrawer, setAlert,
        sampleData, setSampleData, measurementsData, setMeasurementsData, reset, setReset } = props;
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const resTypeGroups = useSelector(selectResourceTypeGroupsConf);
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const permissionList = useSelector(selectPermissionsByKey);
    const updateSample = permissionList['update-sample']?.includes(locationId);
    const fontWeight = useSelector(selectFontWeight);
    
    const renderMeasurements = (row, name) => {
        return !!row.length ? <Stack key={name} direction="column">
            <Typography color='primary.main' variant='body2'><FormattedMessage id={'MEASUREMENTS.' + name} /> ({row.length})</Typography>
            <Stack direction="column" spacing={1}>
                {row.map(el =>
                    <Card key={el.id} sx={{ border: el.complianceIndex === 3 ? '2px solid red' : el.complianceIndex === 4 ? '2px solid cornflowerblue' : '', display: 'inline-block' }} >
                        <CardHeader title={<Typography variant='body1' color={el.complianceIndex === 3 ? "error" : "inherit"} sx={{ fontWeight: fontWeight + 500 }}>{el.resource}</Typography>} action={el.complianceIndex === 3 && <ComplianceTag status={el.complianceIndex} />
                        } />
                        <CardContent sx={{ py: 0 }}>
                            <Typography color={el.complianceIndex === 3 ? "error" : "primary.main"} variant='body1' display='inline' sx={{ fontWeight: fontWeight + 500 }} >{el.value}</Typography>
                            <Typography variant='body2' display='inline' >{el.unit}</Typography>

                        </CardContent>
                    </Card>
                )}
            </Stack>
        </Stack> : null;
    };

    const CustomText = (props) => {
        return <Stack direction='column'>
            <Typography variant='body2' color='primary.main'><FormattedMessage id={props.messageId} /></Typography>
            {props.component ? props.component : <Typography variant='body1'>{props.text}</Typography>}
        </Stack>
    };

    const measurementTypes = resTypeGroups.map((group) => ({
        name: group.name,
        values: values.filter(value => group.resourceTypes.includes(value.id))
    }));
    return <Container disableGutters>
        <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} fullWidth fullScreen={smallScreen}>
            <DialogTitle>
                <FormattedMessage id="FORM.EDIT_SAMPLE" />
            </DialogTitle>
            <Divider />
            <DialogContent>
                <EditSampleForm dialog={true} setOpenDialog={setOpenEditDialog} existingSamplingLog={{ _id: logId, locationId: locationId, timestamp: timestampForDialog, notes: notes, labels: labels, data: values }} toggleDrawer={toggleDrawer} setAlert={setAlert}
                    sampleData={sampleData} setSampleData={setSampleData} measurementsData={measurementsData} setMeasurementsData={setMeasurementsData} reset={reset} setReset={setReset} />
            </DialogContent>
        </Dialog>
        <Stack direction='column' spacing={1}>
            {sampleType === "MANUAL" && updateSample && <Button onClick={() => setOpenEditDialog(true)}><FormattedMessage id="EDIT" /></Button>}
            <CustomText messageId="TIMESTAMP" text={timestamp} />
            <CustomText messageId="SAMPLING_LOG_REPORT.LOCATION_NAME" text={locationName} />
            <CustomText messageId="SAMPLING_LOG_REPORT.SAMPLED_OBJECT_ID" text={locationId} />
            <CustomText messageId="SAMPLING_LOG_REPORT.SAMPLING_LOG_ID" text={logId} />
            <CustomText messageId="SAMPLING_LOG_REPORT.SAMPLE_TYPE" text={<FormattedMessage id={'SAMPLE_LOG.TYPE_' + sampleType} />} />
            <CustomText messageId="SAMPLE_LOG.COLLECTOR" text={collector} />
            {labels ? Object.entries(labels).map(([key, value]) => {
                if (value !== "") {
                    return (
                        <CustomText key={key} messageId={`SAMPLE_MARK_OF_${key.toUpperCase()}`} text={value} />
                    );
                }
                return null;
            }) : null}
            {staging && reviewedBy ? <CustomText messageId="REVIEWED_BY" text={reviewedBy.username} /> : null}
            {!!notes && notes.length ? <CustomText messageId="NOTES" component={
                <Card variant='outlined' sx={{ p: 0.5, fontStyle: 'italic' }}>
                    <Typography variant='body1'>{notes}</Typography>
                </Card>
            } /> : null}

            <Stack direction="column" spacing={2} sx={{ pt: 1 }}>
                {measurementTypes.map(type => renderMeasurements(type.values, type.name))}
            </Stack>

        </Stack>
        {pictures && pictures.length > 0 && <>
            <Typography color='primary.main' variant='body2'><FormattedMessage id='SAMPLE_PICTURES' /> ({pictures.length})</Typography>
            <PictureGallery maxWidth='300px' pictures={pictures.map(el => ({ name: el.split('/').slice(-1), url: process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + el }))} />
        </>}
    </Container>;
}

export default SamplingLogDetails;