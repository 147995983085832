import React, { useCallback, useState } from "react";
import { FormattedMessage } from 'react-intl';
import { useNavigate } from "react-router";
import { AlarmsFilters, AlarmsTableContainer } from "components";
import { Card, CardContent, CardHeader, Stack, Button } from '@mui/material';
import { EditNotificationsOutlined } from '@mui/icons-material';
import { useSelector } from "react-redux";
import { permissionSelector } from "redux/userSlice";

export default function AlarmsView() {
    const [locations, setLocations] = useState([]);
    const [selectAcknowledged, setSelectAcknowledged] = useState(false);
    const [selectUnacknowledged, setSelectUnacknowledged] = useState(true);
    const [selectCleared, setSelectCleared] = useState(false);
    const [selectActive, setSelectActive] = useState(false);
    const [severityTypes, setSeverityTypes] = useState([]);
    const navigate = useNavigate();

    const viewAlarmProfilePermission = useSelector((state) => permissionSelector(state, 'view-alarmProfiles'));

    const filterHandler = useCallback((data, type) => {
        switch (type) {
            case 'severityTypes':
                setSeverityTypes(data);
                return;
            case 'locations':
                setLocations(data);
                return;
            case 'ack':
                setSelectAcknowledged(!selectAcknowledged);
                return;
            case 'unack':
                setSelectUnacknowledged(!selectUnacknowledged);
                return;
            case 'clr':
                setSelectCleared(!selectCleared);
                return;
            case 'act':
                setSelectActive(!selectActive);
                return;
            default:
                setSeverityTypes([]);
                setLocations([]);
                setSelectCleared(false);
                setSelectActive(false);
                setSelectAcknowledged(false);
                setSelectUnacknowledged(false);
                return;
        }
    }, [selectAcknowledged, selectUnacknowledged, selectCleared, selectActive]);

    return <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
        <Card>
            <CardHeader title={<FormattedMessage id="FILTERS" />} action={<Button disabled={!viewAlarmProfilePermission} onClick={() => navigate('/alarms/profiles')} startIcon={<EditNotificationsOutlined />}><FormattedMessage id="ALARM.PROFILES" /></Button>} />
            <CardContent>
                <AlarmsFilters
                    filterHandler={filterHandler}
                    selectedLocations={locations}
                    severityTypesSelected={severityTypes}
                    selectAcknowledged={selectAcknowledged}
                    selectUnacknowledged={selectUnacknowledged}
                    selectCleared={selectCleared}
                    selectActive={selectActive}
                />
            </CardContent>
        </Card>
        <AlarmsTableContainer
            locations={locations.map(loc => loc._id)}
            severities={severityTypes.map(severity => severity.key)}
            selectAcknowledged={selectAcknowledged}
            selectUnacknowledged={selectUnacknowledged}
            selectCleared={selectCleared}
            selectActive={selectActive}
        />
    </Stack>

}