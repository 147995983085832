import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider, Card, CardHeader, Collapse, IconButton, List, ListItemIcon, ListItemText, ListItem, ListItemButton, Badge, Tooltip, Typography, ButtonGroup, Button, Stack, styled } from "@mui/material";
import { FilterAltOffOutlined, VisibilityOffOutlined, Castle, Forest, QuestionMark, CloseOutlined, HelpOutline, ElderlyOutlined, DirectionsBusOutlined, DirectionsCarOutlined, DirectionsBikeOutlined, DirectionsWalkOutlined, AccessibleOutlined, TravelExploreOutlined, ExpandMore } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectAttractionCategoryTypesConf, selectAccessibilityOptionsConf, selectTourismTypesConf } from 'redux/configurationSlice';
import { toggleFilter, toggleFiltersMethod, selectLocationFilters } from 'redux/locationsSlice';


const ExpandMoreCustom = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton size="small" sx={{ mr: 0.5 }} {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -14,
        top: 18,
        padding: '1 1 1 4px',
    },
}));

const TooltipIcon = (props) => {
    return <Typography variant="caption">
        <Tooltip title={<FormattedMessage id={props.messageid} />}>
            <span style={{ cursor: 'help' }}>
                <HelpOutline color="info" sx={theme => ({ width: theme.typography.fontSize, height: theme.typography.fontSize, ml: 0.5, px: 'auto' })} />
            </span>
        </Tooltip>
    </Typography>
}


export default function MapFilters(props) {
    const { hideFilters, closeCB } = props;
    const {
        attractionTypes: attractionFilters,
        tourismOptionTypes: tourismFilters,
        tourismOptionMethod,
        accessibilityOptionTypes: accessibilityFilters,
        accessibilityOptionMethod
    } = useSelector(selectLocationFilters);
    const attractionTypesConf = useSelector(selectAttractionCategoryTypesConf);
    const accessibilityTypesConf = useSelector(selectAccessibilityOptionsConf);
    const tourismTypesConf = useSelector(selectTourismTypesConf);
    const dispatch = useDispatch();
    const [accessibilityExpanded, setAccessibilityExpanded] = useState(true);
    const [tourismExpanded, setTourismExpanded] = useState(true);

    const changeAttractionTypeFilter = (value) => dispatch(toggleFilter({ filterType: 'attractionTypes', filterValue: value }));
    const changeAccessibilityTypeFilter = (value) => dispatch(toggleFilter({ filterType: 'accessibilityOptionTypes', filterValue: value }));
    const changeTourismTypeFilter = (value) => dispatch(toggleFilter({ filterType: 'tourismOptionTypes', filterValue: value }));
    const renderPinIcon = (type) => {
        switch (type) {
            case -1: return <VisibilityOffOutlined />;
            case 2:
                return <Castle color="culturalHeritage" />;
            case 1:
                return <Forest color="success" />;
            case 0:
            default:
                return <QuestionMark color="disabled" />;
        }
    };

    const renderAccessibilityIcons = (type, color) => {
        switch (type) {
            case 5:
                return <ElderlyOutlined color={color || "default"} />;
            case 4:
                return <DirectionsBusOutlined color={color || "default"} />;
            case 3:
                return <DirectionsCarOutlined color={color || "default"} />;
            case 2:
                return <DirectionsBikeOutlined color={color || "default"} />;
            case 1:
                return <DirectionsWalkOutlined color={color || "default"} />;
            case 0:
                return <AccessibleOutlined color={color || "default"} />;
            default:
                return null
        }
    }

    const toggleAccessibilityFilters = () => {
        setAccessibilityExpanded(!accessibilityExpanded);
        // setTourismExpanded(false);
    }
    const toggleTourismFilters = () => {
        setTourismExpanded(!tourismExpanded);
        // setAccessibilityExpanded(false);
    }

    if (hideFilters) return null;

    return <Card elevation={0} sx={{ width: 280, overflowY: 'scroll' }}>
        <CardHeader
            title={<Typography variant="button"><FormattedMessage id="FILTERS" /><TooltipIcon messageid="TOGGLE_TOOLTIP" /></Typography>}
            action={<IconButton onClick={closeCB}><CloseOutlined fontSize="small" /></IconButton>}
        />
        <Divider />
        <List>
            {attractionTypesConf?.map(type => <ListItem key={type.key} disablePadding>
                <ListItemButton dense onClick={() => changeAttractionTypeFilter(type.key)}>
                    <ListItemIcon tabIndex={-1} edge="start">
                        {renderPinIcon(attractionFilters.includes(type.key) ? -1 : type.key)}
                    </ListItemIcon>
                    <ListItemText sx={attractionFilters.includes(type.key) ? theme => ({ color: theme.palette.text.disabled }) : null} primary={<FormattedMessage id={"ATTRACTION." + type.value} />} />
                </ListItemButton>
            </ListItem>)}
        </List>

        <Divider />

        <Stack sx={{ mx: 1, my: 1 }} direction="row" justifyContent="space-between">
            <StyledBadge color="primary" badgeContent={accessibilityFilters.length}>
                <Typography variant="body2" sx={theme => ({ ml: 1.5, my: 'auto', fontWeight: theme.typography.fontWeightMedium })}><FormattedMessage id="ACCESSIBILITY_OPTIONS" />{accessibilityExpanded && <TooltipIcon messageid="SELECT_ITEM" />}</Typography>
            </StyledBadge>
            <ExpandMoreCustom
                expand={accessibilityExpanded}
                onClick={toggleAccessibilityFilters}
                aria-expanded={accessibilityExpanded}
                aria-label="show more"
            >
                <ExpandMore />
            </ExpandMoreCustom>
        </Stack>
        <Collapse in={accessibilityExpanded} timeout="auto" unmountOnExit>
            <Stack sx={{ mx: 2, mb: 2 }} direction="row" justifyContent="space-between">
                <ButtonGroup size="small">
                    <Tooltip title={<FormattedMessage id="INCLUDE_SOME" />} arrow>
                        <Button variant={accessibilityOptionMethod === "some" ? "contained" : "outlined"} onClick={(e) => dispatch(toggleFiltersMethod({ type: 'accessibility', method: "some" }))}>
                            <FormattedMessage id="SOME" />
                        </Button>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="INCLUDE_EVERY" />} arrow>
                        <Button variant={accessibilityOptionMethod === "every" ? "contained" : "outlined"} onClick={(e) => dispatch(toggleFiltersMethod({ type: 'accessibility', method: "every" }))}>
                            <FormattedMessage id="EVERY" />
                        </Button>
                    </Tooltip>
                </ButtonGroup>
                {!!accessibilityFilters.length && <Tooltip title={<FormattedMessage id="CLEAR_ALL" />} arrow>
                    <IconButton size="small" onClick={() => changeAccessibilityTypeFilter([])}><FilterAltOffOutlined fontSize="inherit" /></IconButton>
                </Tooltip>}
            </Stack>
            <Divider />
            <List>
                {accessibilityTypesConf.map(type => <ListItem key={type.key} disablePadding>
                    <ListItemButton dense onClick={() => changeAccessibilityTypeFilter(type.key)}>
                        <ListItemIcon tabIndex={-1} edge="start" >
                            {renderAccessibilityIcons(type.key, !accessibilityFilters.includes(type.key) ? "default" : "primary")}
                        </ListItemIcon>
                        <ListItemText sx={!accessibilityFilters.includes(type.key) ? theme => ({ color: theme.palette.text.disabled }) : null} primary={<FormattedMessage id={"ACCESSIBILITY_OPTIONS." + type.value} />} />
                    </ListItemButton>
                </ListItem>)}
            </List>
        </Collapse>

        <Divider />

        <Stack sx={{ mx: 1, my: 1 }} direction="row" justifyContent="space-between">
            <StyledBadge color="primary" badgeContent={tourismFilters.length}>
                <Typography variant="body2" sx={theme => ({ ml: 1.5, my: 'auto', fontWeight: theme.typography.fontWeightMedium })}><FormattedMessage id="TOURISM.TYPE" />{tourismExpanded && <TooltipIcon messageid="SELECT_ITEM" />}</Typography>
            </StyledBadge>
            <ExpandMoreCustom
                expand={tourismExpanded}
                onClick={toggleTourismFilters}
                aria-expanded={tourismExpanded}
                aria-label="show more"
            >
                <ExpandMore />
            </ExpandMoreCustom>
        </Stack>
        <Collapse in={tourismExpanded} timeout="auto" unmountOnExit>
            <Stack sx={{ mx: 2, mb: 2 }} direction="row" justifyContent="space-between">
                <ButtonGroup size="small">
                    <Tooltip title={<FormattedMessage id="INCLUDE_SOME" />} arrow>
                        <Button variant={tourismOptionMethod === "some" ? "contained" : "outlined"} onClick={(e) => dispatch(toggleFiltersMethod({ type: 'tourism', method: "some" }))}>
                            <FormattedMessage id="SOME" />
                        </Button>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="INCLUDE_EVERY" />} arrow>
                        <Button variant={tourismOptionMethod === "every" ? "contained" : "outlined"} onClick={(e) => dispatch(toggleFiltersMethod({ type: 'tourism', method: "every" }))}>
                            <FormattedMessage id="EVERY" />
                        </Button>
                    </Tooltip>
                </ButtonGroup>
                {!!tourismFilters.length && <Tooltip title={<FormattedMessage id="CLEAR_ALL" />} arrow>
                    <IconButton size="small" onClick={() => changeTourismTypeFilter([])}><FilterAltOffOutlined fontSize="inherit" /></IconButton>
                </Tooltip>}
            </Stack>
            <Divider />
            <List>
                {tourismTypesConf.map(type => <ListItem key={type.key} disablePadding>
                    <ListItemButton dense onClick={() => changeTourismTypeFilter(type.key)}>
                        <ListItemIcon tabIndex={-1} edge="start">
                            <TravelExploreOutlined color={!tourismFilters.includes(type.key) ? "default" : "primary"} />
                        </ListItemIcon>
                        <ListItemText sx={!tourismFilters.includes(type.key) ? theme => ({ color: theme.palette.text.disabled }) : null} primary={<FormattedMessage id={"TOURISM." + type.value} />} />
                    </ListItemButton>
                </ListItem>)}
            </List>
        </Collapse>

        <Divider />
    </Card>
}