import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectPermissionsByKey, selectResourcesTypesConf, selectResourceTypeGroupsConf, selectStatusCodesConf, selectStatusCodesTypesConf } from "redux/configurationSlice";
import { selectLocationDetails } from "redux/locationsSlice";
import { updateLocationStatusCode } from "redux/locationsSlice";
import { userSelector } from "redux/userSlice";
import { Collapse, Box, Button, TextField, Typography, useTheme, useMediaQuery, IconButton, Pagination, DialogActions, FormLabel, FormControl } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ExpandMore } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, hr } from "date-fns/locale"
import { formatDateLocale } from "utils";
import EditSampleCustomFields from "./EditSampleCustomFields";
import API from "api";


const ExpandMoreCustom = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} color="primary" >
    <ExpandMore />
  </IconButton>;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default function EditSampleForm(props) {
  const { existingSamplingLog, historyReportData, historyPage, setReportData, dialog, setOpenDialog, toggleDrawer, setAlert, sampleData, setSampleData, history, measurementsData, setMeasurementsData, reset, setReset } = props;
  const location = useSelector((state) => selectLocationDetails(state, existingSamplingLog.locationId));
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const user = useSelector(userSelector);
  const resourceTypesConf = useSelector(selectResourcesTypesConf);
  const statusCodes = useSelector(selectStatusCodesConf).COMPLIANCE;
  const statusCodeTypes = useSelector(selectStatusCodesTypesConf);
  const manualResourceTypes = useSelector(selectResourceTypeGroupsConf)?.filter(el => el.name !== "SERVICE" && el.name !== "SENSOR");
  const availableTypesKeys = existingSamplingLog?.data?.map(item => Number(item?.resourceTypeKey));
  const resourceTypes = resourceTypesConf.filter(resource => availableTypesKeys.includes(resource.key) && manualResourceTypes.flatMap(el => el.resourceTypes).includes(resource.key) && !resource.name.includes("MANUAL"))
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = Object.fromEntries(new Map([...resourceTypes?.map(el => {
    const foundMeasure = existingSamplingLog?.data?.find(item => Number(item?.resourceTypeKey) === el?.key);
  
    if (el.dataType === 'Object') return [el.key, { value: foundMeasure.value !== "Bez" && foundMeasure.value !== "-", description: foundMeasure.value }]
    else return [el.key, foundMeasure.value.toString()]
  }), ['notes', existingSamplingLog.notes], ['timestamp', new Date(existingSamplingLog.timestamp)]]));


  const [errorMsg, setErrorMsg] = useState(null);
  const [expanded, setExpanded] = useState(manualResourceTypes.map((el, i) => true));
  const [page, setPage] = useState(Object.fromEntries(new Map([...manualResourceTypes.map(el => {
    return [el.name, 1]
  })])));
  const [filterName, setFilterName] = useState('');
  const [labels, setLabels] = useState({
    HZJZ: existingSamplingLog.labels?.HZJZ || '',
    supplier: existingSamplingLog.labels?.supplier || ''
  })
  const permissionList = useSelector(selectPermissionsByKey);
  const updateSample = permissionList['update-sample']?.includes(location._id);
  const [hasChanges, setHasChanges] = useState(false);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);

  const handleLabelsChange = (name) => (event) => {
    const { value } = event.target;
    setLabels((prevLabels) => ({
      ...prevLabels,
      [name]: value,
    }));
  };
  const updateTableSampleData = (updatedSample) => {
    const refreshedData = sampleData.map(sample => {
      if (sample.id === updatedSample._id) {
        const status = statusCodes.find(el => el.key === updatedSample.complianceIndex);
        return { ...sample, sampleNotes: updatedSample.notes, date: formatDateLocale(updatedSample.timestamp), quality: intl.formatMessage({ id: `SAMPLE_LOG.STATUS.COMPLIANCE.${status.value}` }) }
      }
      else return sample;
    })
    setSampleData(refreshedData);
  }

  const updateTableMeasurementsData = (updatedSample) => {
    const refreshedData = measurementsData.map(measurement => {
      if (measurement.samplingLogId === updatedSample._id) {
        const foundUpdatedMeasurement = updatedSample.data.find(meas => meas.resourceTypeKey === measurement.resourceType.key)
        return { ...measurement, date: formatDateLocale(foundUpdatedMeasurement.timestamp), value: foundUpdatedMeasurement.value }
      }
      else return measurement;
    })
    setMeasurementsData(refreshedData);
  }


  const validationSchema = resourceTypes.map(resource => {
    if (resource.range.min !== null && resource.range.max !== null) {
      return [
        resource.key.toString(),
        Yup.number().typeError(intl.formatMessage({ id: 'FORM.TYPE_ERROR' }))
          .min(resource.range.min, `${intl.formatMessage({ id: 'FORM.MIN_ERROR' })} ${resource.range.min}`)
          .max(resource.range.max, `${intl.formatMessage({ id: 'FORM.MAX_ERROR' })} ${resource.range.max}`)
          .required(intl.formatMessage({ id: "MISSING_REQUIRED_FIELDS" }))
      ];
    }
    else {
      if (resource.dataType === 'Float') return [resource.key.toString(),
      Yup.number().typeError(intl.formatMessage({ id: 'FORM.TYPE_ERROR' }))
        .required(intl.formatMessage({ id: "MISSING_REQUIRED_FIELDS" }))
      ]
      else if (resource.dataType === 'Object') return [resource.key.toString(),
      Yup.object().typeError(intl.formatMessage({ id: 'FORM.TYPE_ERROR' }))
        .required(intl.formatMessage({ id: "MISSING_REQUIRED_FIELDS" }))
      ]
      else if (resource.dataType === 'String') return [resource.key.toString(),
      Yup.string().typeError(intl.formatMessage({ id: 'FORM.TYPE_ERROR' }))
        .required(intl.formatMessage({ id: "MISSING_REQUIRED_FIELDS" }))
      ]
      else return [resource.key.toString(),
      Yup.boolean().typeError(intl.formatMessage({ id: 'FORM.TYPE_ERROR' }))
        .required(intl.formatMessage({ id: "MISSING_REQUIRED_FIELDS" }))
      ]
    }
  });
  validationSchema.push([
    'notes',
    Yup.string().typeError(intl.formatMessage({ id: 'FORM.TYPE_ERROR' }))
      .max(256, intl.formatMessage({ id: 'FORM.NOTES_ERROR' }))
  ]);
  validationSchema.push([
    'timestamp',
    Yup.date().typeError(intl.formatMessage({ id: 'FORM.DATE_ERROR' }))
      .max(new Date(), intl.formatMessage({ id: 'FORM.MAX_DATE_ERROR' }))
  ]);

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: false,
    validationSchema: Yup.object(Object.fromEntries(validationSchema)),
    onReset: () => {
      if (dialog) setOpenDialog(false);
      else if (history) navigate('/sampleEntry/history');
      else navigate('/sampleEntry');
    },
    onSubmit: (values) => {
      setDisabledSubmitButton(true);
      const measurementValues = [];
      availableTypesKeys.forEach(key => {
        const foundResourceType = resourceTypes.find(type => type.key === key);
        if (foundResourceType?.dataType === "Object") {
          measurementValues.push({
            resourceTypeKey: key,
            value: values[key],
            unit: foundResourceType.unit
          })
        }
        else {
          measurementValues.push({
            resourceTypeKey: key,
            value: Number(values[key]),
            unit: foundResourceType.unit
          })
        }

      });

      if (updateSample) {
        API.samplingLogs.editSample(user.token, existingSamplingLog._id, location._id.toString(), measurementValues, values.timestamp, values.notes, labels).then(response => {
          if (response.data) {
            const updatedSample = response.data;

            const pictures = response.data.pictures.map((imgPath) => {
              // Transform the 'pictures' array from the API response into an array of objects 
              // Specific format needed for the PictureGallery component
              const imgName = imgPath.toString().split("\\").slice(-1).toString();
              const url = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_IMG_PATH}${imgPath}`;
            
              return { name: imgName, url: url }
            })

            updatedSample.pictures = pictures

            setAlert({ open: true, message: {id: "SAMPLE_LOG.SUCCESS_UPDATE"}, severity: "success" });
            // dispatch action to update statusCodes for location and LocationGroup
            if (updatedSample.complianceIndex || statusCodeTypes.length) {
              dispatch(updateLocationStatusCode({
                locationId: location._id,
                locationGroupId: location.locationGroupId,
                locationStatusCode: updatedSample.locationStatusCode,
                groupStatusCode: updatedSample.groupStatusCode,
                complianceIndex: updatedSample.complianceIndex
              }))
            }
            if (!dialog) {
              setReportData({ ...updatedSample, collector: updatedSample.source.collector });
              if (history) {
                const newReportDataSamples = historyReportData.samplingLogsArray.map((sample, index) => {
                  if (historyPage === index) return { ...updatedSample, collector: updatedSample.source.collector }
                  else return sample;
                })
                setReportData({ ...historyReportData, samplingLogsArray: newReportDataSamples });
                navigate('/sampleEntry/history');
              }
              else {
                setReportData({ ...updatedSample, collector: updatedSample.source.collector });
                navigate('/sampleEntry');
              }
            }
            else {
              if (sampleData) updateTableSampleData(updatedSample);
              if (measurementsData) updateTableMeasurementsData(updatedSample);
              if (setReset) setReset(reset + 1);
              setOpenDialog(false);
              toggleDrawer();
            }
          }
        }).catch((error) => {
          setAlert({ open: true, messageId: (error.data && error.data.id) || "ERROR.NOT_UPDATED", severity: "error" });
          setDisabledSubmitButton(false);
          if (error.status === 403) setErrorMsg(<FormattedMessage id="ACCESS_DENIED" />);
          else setErrorMsg(error.data?.message || "");
        });
      }


      else setErrorMsg(<FormattedMessage id="FORM.EMPTY_VALUES" />);
    }
  });

  useEffect(() => {
    setErrorMsg("");
    const initialVersionObject = initialValues;
    Object.assign(initialVersionObject, existingSamplingLog.labels)
    const finalVersionObject = formik.values;
    Object.assign(finalVersionObject, labels)
    setHasChanges(JSON.stringify(finalVersionObject) !== JSON.stringify(initialVersionObject));

  }, [formik.values, hasChanges, initialValues, labels, existingSamplingLog.labels])

  const renderForm = useCallback(() => {
    const perPageNumber = (() => {
      if (smallScreen) return 4;
      else if (mediumScreen) return 6;
      else return 8
    })();
    return (
      <Box component="form" onSubmit={formik.handleSubmit} >
        {errorMsg ? <Typography sx={{ textAlign: 'center', mb: 2, color: theme.palette.error.main }}>{errorMsg}</Typography> : null}
        <FormControl sx={{ mb: 2 }}>
          <FormLabel ><FormattedMessage id="LOCATION" /></FormLabel>
          <Typography variant="subtitle1">{location.name}</Typography>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
          {smallScreen ? <MobileDateTimePicker
            label={<FormattedMessage id="TIMESTAMP" />}
            value={formik.values['timestamp']}
            onChange={(e) => formik.setFieldValue('timestamp', new Date(e))}
            renderInput={(params) => <TextField {...params} size="small" fullWidth error={Boolean(formik.errors['timestamp'])}
              helperText={formik.errors['timestamp']} />}
          /> : <DateTimePicker
            label={<FormattedMessage id="TIMESTAMP" />}
            value={formik.values['timestamp']}
            onChange={(e) => formik.setFieldValue('timestamp', new Date(e))}
            renderInput={(params) => <TextField {...params} size="small" fullWidth error={Boolean(formik.errors['timestamp'])}
              helperText={formik.errors['timestamp']} />}
          />}
        </LocalizationProvider>

        <TextField
          type="search"
          id="outlined-helperText"
          label={<FormattedMessage id="SEARCH_BY.RESOURCE_NAME" />}
          value={filterName}
          onChange={(e) => {
            setFilterName(e.target.value); setPage(Object.fromEntries(new Map([...manualResourceTypes.map(el => {
              return [el.name, 1]
            })])))
          }}
          fullWidth
          margin="normal"
          sx={{ mt: 4 }}
          size="small"
        />

        {manualResourceTypes.map((resGroup, index) => {
          const filteredResourceTypes = resourceTypes.filter(res => {
            if (!filterName) return resGroup.resourceTypes.includes(res.key);
            else return resGroup.resourceTypes.includes(res.key) && intl.formatMessage({ id: res.name }).toUpperCase().includes(filterName.toUpperCase())
          })
          const pageCount = Math.ceil(filteredResourceTypes.length / perPageNumber);

          if (filteredResourceTypes.length)
            return <div key={index}>
              <span style={{ cursor: 'pointer' }} onClick={() => setExpanded(manualResourceTypes.map((el, i) => i === index ? !expanded[i] : expanded[i]))}>
                <Typography sx={{ pt: 2, display: 'inline-block' }} key={resGroup._id} color="primary" variant="h6"><FormattedMessage id={"RESOURCE_TYPE.GROUP." + resGroup.name} /></Typography>
                <ExpandMoreCustom sx={{ mb: 1 }} expand={expanded[index]} />
              </span>
              <Collapse in={expanded[index]}>
                {
                  filteredResourceTypes.slice((page[resGroup.name] - 1) * perPageNumber, ((page[resGroup.name] - 1) * perPageNumber) + perPageNumber).map(resource => {
                    return <EditSampleCustomFields resource={resource} formik={formik} key={resource.key} existingSamplingLog={existingSamplingLog} />
                  }
                  )
                }
                {filteredResourceTypes.length > perPageNumber && <Pagination count={pageCount} page={page[resGroup.name]} onChange={(e, value) => { setPage((prevState) => ({ ...prevState, [resGroup.name]: value })) }} siblingCount={2} size={smallScreen || mediumScreen ? 'small' : 'medium'} />}
              </Collapse>
            </div>
          return null
        })}

        {Object.keys(labels).length ?
          <>
            <Typography sx={{ pt: 2 }} color="primary" variant="h6"><FormattedMessage id="SAMPLE_MARKS" /></Typography>
            {Object.keys(labels).map((property) => (
              <TextField
                key={property}
                variant="standard"
                label={<FormattedMessage id={`SAMPLE_MARK_OF_${property.toUpperCase()}`} />}
                fullWidth
                margin="normal"
                sx={{ p: 0, mt: 1 }}
                type="text"
                size="small"
                name={property}
                value={labels[property]}
                onChange={handleLabelsChange(property)}
              />
            ))}
          </>
          : null}

        <Typography sx={{ pt: 2 }} color="primary" variant="h6"><FormattedMessage id="NOTES" /></Typography>
        <TextField
          multiline
          rows={1}
          name="notes"
          placeholder={intl.formatMessage({ id: "NOTE_PLACEHOLDER" })}
          value={formik.values['notes']}
          onChange={formik.handleChange}
          error={Boolean(formik.errors['notes'])}
          helperText={formik.errors['notes']}
          margin="normal"
          variant="standard"
          fullWidth
        />

        <DialogActions>
          <Button color="warning" onClick={formik.handleReset} ><FormattedMessage id="CANCEL" /></Button>
          <Button disabled={Object.keys(formik.errors).length ? true : false || errorMsg ? true : false || !hasChanges || disabledSubmitButton} type="submit"><FormattedMessage id="EDIT" /></Button>
        </DialogActions>
      </Box>
    )
  }, [errorMsg, intl, location, resourceTypes, theme.palette, formik, smallScreen, expanded, manualResourceTypes, mediumScreen, filterName, page, labels, existingSamplingLog, hasChanges, disabledSubmitButton]);

  return renderForm();
}