import React, { forwardRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { permissionSelector, userSelector } from 'redux/userSlice';
import { useSelector } from 'react-redux';
import API from 'api';

import { ListItemButton, ListItemIcon, ListItemText, Divider, List, Tooltip, Badge } from '@mui/material';
import { HomeOutlined, LocationOnOutlined, EditLocationOutlined, ReportOutlined, QuestionMarkOutlined, ShowChartOutlined, BarChartOutlined, AddchartOutlined, NoteAddOutlined, NotificationImportantOutlined, ContentPasteOutlined } from '@mui/icons-material';

const BadgeNumber = forwardRef((props, ref) => {
    const { token } = useSelector(userSelector);
    const viewerPermission = useSelector((state) => permissionSelector(state, 'view-staging-location'));
    const reviewerPermission = useSelector((state) => permissionSelector(state, 'review-staging-location'));
    const approverPermission = useSelector((state) => permissionSelector(state, 'approve-staging-location'));
    const [badgeNumber, setBadgeNumber] = useState(0);
    const { touchRippleRef, ...rest } = props;

    useEffect(() => {
        const getBadgeNumber = async (role) => {
            let promise = await API.locations.getStagingLocationCount(token, role);
            setBadgeNumber(promise ? promise.data : 0);
        }

        if (approverPermission) getBadgeNumber("approve");
        else if (reviewerPermission) getBadgeNumber("review");
        else if (viewerPermission) getBadgeNumber("view");
        else setBadgeNumber(0);
    }, [token, viewerPermission, reviewerPermission, approverPermission]);

    return <Badge {...rest} ref={ref} color='primary' badgeContent={badgeNumber}><EditLocationOutlined /></Badge>;
});

const CustomListButton = ({ item, selectedItem, open }) => {
    const navigate = useNavigate();

    return (
        <ListItemButton key={item.item} selected={item.item === selectedItem} onClick={() => item.onClick(navigate)}>
            <ListItemIcon>
                <Tooltip title={open ? "" : item.textElement} placement="right" arrow>
                    {item.iconElement}
                </Tooltip>
            </ListItemIcon>
            <ListItemText primary={item.textElement} />
        </ListItemButton>
    );
};

const CustomMenuItems = (props) => {
    const { secondaryItems } = props;
    const permissionObject = {
        'view-staging-location': useSelector((state) => permissionSelector(state, 'view-staging-location')),
        'view-staging-sample': useSelector((state) => permissionSelector(state, 'view-staging-sample')),
        'view-report': useSelector((state) => permissionSelector(state, 'view-report')),
        'view-sample': useSelector((state) => permissionSelector(state, 'view-sample')),
        'view-measurements': useSelector((state) => permissionSelector(state, 'view-measurements')),
        'create-sample': useSelector((state) => permissionSelector(state, 'create-sample')),
        'view-alarms': useSelector((state) => permissionSelector(state, 'view-alarms')),
        'view-notes': useSelector((state) => permissionSelector(state, 'view-notes')),
    };

    const homeArray = [
        {
            item: 'HomePage',
            onClick: (navigateCallback) => navigateCallback("/map"),
            iconElement: <HomeOutlined />,
            textElement: <FormattedMessage id="HOME" />
        },
    ].filter(item => !item.permission || permissionObject[item.permission]);

    const locationsArray = [
        {
            item: 'LocationsPage',
            onClick: (navigateCallback) => navigateCallback("/locations"),
            iconElement: <LocationOnOutlined />,
            textElement: <FormattedMessage id="LOCATIONS" />
        },
        {
            item: 'StagingLocationsPage',
            onClick: (navigateCallback) => navigateCallback("/staging-locations"),
            iconElement: <BadgeNumber />,
            textElement: <FormattedMessage id="STAGING_LOCATIONS" />,
            permission: "view-staging-location"
        },
    ].filter(item => !item.permission || permissionObject[item.permission]);

    const reportArray = [
        {
            item: 'MeasurementsPage',
            onClick: (navigateCallback) => navigateCallback("/measurements"),
            iconElement: <ShowChartOutlined />,
            textElement: <FormattedMessage id="MEASUREMENTS" />,
            permission: "view-measurements"
        },
        {
            item: 'SamplesPage',
            onClick: (navigateCallback) => navigateCallback("/samples"),
            iconElement: <BarChartOutlined />,
            textElement: <FormattedMessage id="SAMPLES" />,
            permission: "view-sample"
        }
    ].filter(item => !item.permission || permissionObject[item.permission]);

    const samplesArray = [
        {
            item: 'AddSamplePage',
            onClick: (navigateCallback) => navigateCallback("/sampleEntry"),
            iconElement: <AddchartOutlined />,
            textElement: <FormattedMessage id="ADD_SAMPLE" />,
            permission: 'create-sample'
        },
        {
            item: 'AddSampleHistoryPage',
            onClick: (navigateCallback) => navigateCallback("/sampleEntry/history"),
            iconElement: <NoteAddOutlined />,
            textElement: <FormattedMessage id="ADD_SAMPLE_FILE" />,
            permission: 'create-sample'
        },
        {
            item: 'IncidentReportsPage',
            onClick: (navigateCallback) => navigateCallback("/incident-reports"),
            iconElement: <ReportOutlined />,
            textElement: <FormattedMessage id="INCIDENT_REPORTS" />,
            permission: "view-report"
        },
        {
            item: 'AlarmsPage',
            onClick: (navigateCallback) => navigateCallback("/alarms"),
            iconElement: <NotificationImportantOutlined />,
            textElement: <FormattedMessage id="ALARMS" />,
            permission: "view-alarms"
        },
        {
            item: 'NotesPage',
            onClick: (navigateCallback) => navigateCallback("/notes"),
            iconElement: <ContentPasteOutlined />,
            textElement: <FormattedMessage id="NOTES" />,
            permission: "view-notes"
        },
    ].filter(item => !item.permission || permissionObject[item.permission]);

    const aboutArray = [
        {
            item: 'AboutPage',
            onClick: (navigateCallback) => navigateCallback("/about"),
            iconElement: <QuestionMarkOutlined />,
            textElement: <FormattedMessage id="INNOVET_PROJECT" />
        },
    ].filter(item => !item.permission || permissionObject[item.permission]);


    return (
        <List component="nav" style={{ height: 1 }}>
            {homeArray.map((item, i) => <CustomListButton key={i} item={item} {...props} />)}
            {homeArray.length > 0 && <Divider sx={{ my: 1 }} />}
            {locationsArray.map((item, i) => <CustomListButton key={i} item={item} {...props} />)}
            {locationsArray.length > 0 && <Divider sx={{ my: 1 }} />}
            {reportArray.map((item, i) => <CustomListButton key={i} item={item} {...props} />)}
            {reportArray.length > 0 && <Divider sx={{ my: 1 }} />}
            {secondaryItems && samplesArray.map((item, i) => <CustomListButton key={i} item={item} {...props} />)}
            {secondaryItems && samplesArray.length > 0 && <Divider sx={{ my: 1 }} />}
            {aboutArray.map((item, i) => <CustomListButton key={i} item={item} {...props} />)}
        </List>
    );
};

export default CustomMenuItems;
