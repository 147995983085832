import React, { useCallback, useState, useRef, useEffect } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { selectAttractionCategoryTypesConf, selectTourismTypesConf, selectAccessibilityOptionsConf, selectResourceTypeGroupsConf, selectResourcesTypesConf } from "redux/configurationSlice";
import { userSelector, permissionSelector } from "redux/userSlice";
import { selectLocations } from "redux/locationsSlice";
import { AddImagesForm, PictureUploadButton, MapComponent, MapElementNew, AlertSnackbar, ConfirmationDialog, AlbumDetails, DisplayResourceName } from "components";
import { Vector } from 'ol/source';
import { Alert, Card, Box, Dialog, DialogTitle, DialogContent, OutlinedInput, TextField, Tooltip, InputAdornment, Autocomplete, Avatar, Stack, Button, useMediaQuery, Divider, DialogActions, Grid, IconButton, CardContent, CardActions, CardHeader, Typography, FormLabel, FormControl, FormControlLabel, Collapse, Checkbox, FormGroup } from "@mui/material";
import { AddPhotoAlternate, Forest, Castle, QuestionMark, SaveOutlined, SwapHorizontalCircleOutlined, SwapVerticalCircleOutlined, DeleteOutlined, ExpandMoreOutlined, ExpandLessOutlined } from "@mui/icons-material";
import API from 'api';
import { imageResize } from "utils";

const maxWidth = 2600
const maxHeight = 1500

const CustomInputField = (props) => {
    const { value, onChange, messageId, children } = props;
    const [focused, setFocused] = useState(false);

    return <OutlinedInput
        fullWidth
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        id={messageId}
        type="number"
        inputProps={{ inputMode: 'decimal', step: 0.000001 }}
        value={parseFloat(value).toFixed(6)}
        onChange={onChange}
        size="small"
        startAdornment={
            <InputAdornment position="start">
                <Tooltip title={<FormattedMessage id={messageId} />} arrow>
                    {React.cloneElement(children, { color: focused ? "primary" : "inherit" })}
                </Tooltip>
            </InputAdornment>
        }
    />
}


export default function NewStagingLocationDialog(props) {
    const { open, onClose, location, pinnedPosition, onSubmit, updateLocation, updateStagingLocation, noSaveOption, onDelete } = props;
    const { token, username } = useSelector(userSelector);
    const { _id: locationGroupId } = useSelector(selectLocations)[0]
    const intl = useIntl();
    const fullScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const attractionTypes = useSelector(selectAttractionCategoryTypesConf);
    const resourceTypeGroups = useSelector(selectResourceTypeGroupsConf);
    const resourceTypes = useSelector(selectResourcesTypesConf);
    const reviewerPermission = useSelector((state) => permissionSelector(state, 'review-staging-location'));
    const creatorPermission = useSelector((state) => permissionSelector(state, 'create-staging-location'));
    const adminCreatorPermission = useSelector((state) => permissionSelector(state, 'create-location'));
    const viewMeasurementsPermission = useSelector((state) => permissionSelector(state, 'view-measurements'));
    const createSamplePermission = useSelector((state) => permissionSelector(state, 'create-sample'));
    const viewSamplePermission = useSelector((state) => permissionSelector(state, 'view-sample'));
    const tourismTypes = useSelector(selectTourismTypesConf);
    const accessibilityOptions = useSelector(selectAccessibilityOptionsConf);
    const [pointType, setPointType] = useState(0);
    const [selectedType, setSelectedType] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [position, setPosition] = useState([...pinnedPosition]);
    const [alert, setAlert] = useState({ open: false });
    const [submittedFlag, setSubmittedFlag] = useState(false);
    const [selectedAccessibility, setSelectedAccessibility] = useState([]);
    const [additionalInfo, setAdditionalInfo] = useState("")
    const markerSource = useRef(new Vector());
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [descLimitOverFlow, setDescLimitOverflow] = useState(false);
    const [isOpenAlbumDialog, setIsOpenAlbumDialog] = useState(false);
    const [tempLocation, setTempLocation] = useState(location);
    const [newAlbum, setNewAlbum] = useState(true);
    const [isAlbumUploaded, setIsAlbumUploaded] = useState(false);
    const [locationFormResizedItems, setLocationFormResizedItems] = useState([]);
    const [albumImageDescriptions, setAlbumImageDescriptions] = useState([]);
    const [locationFormPictures, setLocationFormPictures] = useState([]);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
    const [expandedResourceGroups, setExpandedResourceGroups] = useState(Object.fromEntries(resourceTypeGroups.map(el => [el.name, true])));
    const [resources, setResources] = useState(Object.fromEntries(resourceTypes.map(el => (
        [el.key, {
            name: el.name,
            group: el.resourceTypeGroupId.name,
            checked: location && (location.resourceTypes || location.serviceResourceTypes) ?
                (location?.resourceTypes?.includes(parseInt(el.key)) || location?.serviceResourceTypes?.includes(parseInt(el.key))) : false,
            resource: el
        }]
    ))));

    const handleOpenAlbumDialog = () => {
        setIsOpenAlbumDialog(true);
    }

    const handleCloseAlbumDialog = () => {
        setIsOpenAlbumDialog(false);
    }

    const handleExpandResourceGroupClick = (rtGroup, value) => {
        const expandResGroups = { ...expandedResourceGroups }
        if (rtGroup === "all") {
            for (const rtg in expandResGroups) {
                expandResGroups[rtg] = value;
            }
        }
        else {
            expandResGroups[rtGroup] = !expandResGroups[rtGroup]
        }

        setExpandedResourceGroups(expandResGroups);
    };

    const handleResourcesGroupCheck = (checkFlag, rtGroup) => {
        const res = { ...resources };
        for (const rtKey in res) {
            if (res[rtKey].group === rtGroup) res[rtKey].checked = checkFlag
        }
        setResources(res);
    }

    const handleResourcesCheck = (checkFlag, key) => {
        const res = { ...resources };
        res[key].checked = checkFlag;
        setResources(res);
    }

    const resourceTypesCheckboxes = Object.entries(resources).map(([key, el]) => {
        return <FormControlLabel
            key={key}
            control={
                <Checkbox
                    sx={{ px: '9px', py: '5px' }}
                    checked={el.checked}
                    onChange={(e) => handleResourcesCheck(e.target.checked, key)}
                />
            }
            label={<DisplayResourceName resource={el.resource} />}
            name={el.group}
        />
    });

    useEffect(() => {
        if (location) {
            setPointType(location.touristAttraction?.attractionType);
            setSelectedType(location.touristAttraction?.tourismTypes);
            setName(location.name);
            setDescription(location.description && location.description[intl.locale] ? location.description[intl.locale] : "");
            setAdditionalInfo(location.touristAttraction?.additionalInfo ? location.touristAttraction.additionalInfo : "");
            setSelectedAccessibility(location.touristAttraction?.accessibilityOptions);
        }
    }, [location, intl]);
    useEffect(() => {
        if (description.length > 1500) {
            setDescLimitOverflow(true);
            setAlert({ open: true, message: { id: "LOCATION_DESCRIPTION_OVERFLOW" }, severity: "error" });
        }
        else {
            setDescLimitOverflow(false);
            setAlert({ open: false })
        }

    }, [description])

    useEffect(() => {
        setTempLocation({
            ...(location && { _id: location._id }),
            locationGroupId: locationGroupId,
            type: selectedType,
            name: name,
            description: {
                en: '',
                hr: '',
                ...(location && location.description),
                [intl.locale]: description
            },
            submitted: false,
            latitude: position[1],
            longitude: position[0],
            resourceTypes: Object.entries(resources).map(([key, value]) => value.checked && value.group !== "SERVICE" ? parseInt(key) : null).filter(el => el !== null).join(','),
            serviceResourceTypes: Object.entries(resources).map(([key, value]) => value.checked && value.group === "SERVICE" ? parseInt(key) : null).filter(el => el !== null).join(','),
            touristAttraction: {
                attractionType: pointType,
                tourismTypes: selectedType,
                accessibilityOptions: selectedAccessibility,
                additionalInfo: additionalInfo,
            }
        })
    }, [additionalInfo, description, intl.locale, location, locationGroupId, name, pointType, position, selectedAccessibility, selectedType, resources])

    useEffect(() => {
        if (name?.trim() === '' || !selectedType.length || !selectedAccessibility.length || description?.trim() === '') setDisabledSubmitButton(true);
        else setDisabledSubmitButton(false);
    }, [description, name, selectedAccessibility, selectedType]);

    const onSubmitHandler = useCallback((createFlag = true, saveFlag = false) => {
        setSubmittedFlag(true);
        const newLocation = {
            ...(location && { _id: location._id }),
            locationGroupId: locationGroupId,
            type: selectedType,
            name: name,
            description: {
                en: '',
                hr: '',
                ...(location && location.description),
                [intl.locale]: description
            },
            ...(!saveFlag && { submitted: true }),
            latitude: position[1],
            longitude: position[0],
            resourceTypes: Object.entries(resources).map(([key, value]) => value.checked && value.group !== "SERVICE" ? parseInt(key) : null).filter(el => el !== null).join(','),
            serviceResourceTypes: Object.entries(resources).map(([key, value]) => value.checked && value.group === "SERVICE" ? parseInt(key) : null).filter(el => el !== null).join(','),
            touristAttraction: {
                attractionType: pointType,
                tourismTypes: selectedType,
                accessibilityOptions: selectedAccessibility,
                additionalInfo: additionalInfo,
            }
        };

        const onComplete = (type, data) => {
            setSubmittedFlag(false);
            setName("");
            setSelectedType([]);
            setPointType(0)
            setDescription("");
            setAdditionalInfo("");
            setSelectedAccessibility([]);
            onClose();
            if (isAlbumUploaded) {
                const createdAlbum = {
                    ...newAlbum,
                    locationId: data._id
                }

                API.albums.createAlbum(token, createdAlbum, locationFormResizedItems, albumImageDescriptions).then((items) => {
                }).catch(error => {
                    setAlert({ open: true, message: "error", severity: "error" });
                    console.error("Error posting new album:", error);
                })
            }
            if (onSubmit && typeof onSubmit === 'function') {
                onSubmit(type, data)
            };

        }
        const onError = (error) => {
            setSubmittedFlag(false);
            setAlert({ open: true, message: error.data, severity: "error" });
            console.error("Get Location Points of Interest has failed, reason: ", error);
        }

        let updatedImage = null
        async function resizeImageHelper() {
            try {
                const resizedImage = await imageResize(image[0], maxWidth, maxHeight);
                updatedImage = [...image];
                updatedImage[0] = resizedImage;
            } catch (error) {
                onError(error);
            }
        }

        async function processSubmission() {
            //If there is no picture resize will not be called and the value of updated value will remain null
            if (image !== null) await resizeImageHelper();

            if (location && updateLocation) API.locations.updateLocation(token, location._id, newLocation, updatedImage).then(({ data }) => onComplete("UPDATE", data)).catch(onError);
            else if (location && updateStagingLocation) API.locations.updateUserStagingLocation(token, newLocation, updatedImage).then(({ data }) => onComplete(saveFlag ? "SAVED" : "FOR_REVIEW", data)).catch(onError);
            else if (location) API.locations.updateStagingLocation(token, newLocation, updatedImage).then(({ data }) => onComplete("REVIEW_UPDATE", data)).catch(onError);
            else if (createFlag && !adminCreatorPermission) API.locations.createStagingLocation(token, newLocation, updatedImage).then(({ data }) => onComplete(saveFlag ? "SAVED" : "FOR_REVIEW", data)).catch(onError);
            else if (createFlag && adminCreatorPermission) API.locations.createLocation(token, newLocation, updatedImage).then(({ data }) => onComplete("ADDED", data)).catch(onError);
            else API.locations.createAndReviewStagingLocation(token, newLocation, updatedImage).then(({ data }) => onComplete("FOR_APPROVE", data)).catch(onError);
        }

        processSubmission()

    }, [location, name, description, additionalInfo, selectedType, position, locationGroupId, selectedAccessibility, pointType, updateLocation, updateStagingLocation, token, adminCreatorPermission, image, intl, onClose, onSubmit, isAlbumUploaded, newAlbum, locationFormResizedItems, albumImageDescriptions, resources]);

    const renderPinIcon = useCallback(() => {
        switch (pointType) {
            case 2:
                return <Castle />;
            case 1:
                return <Forest />;
            case 0:
            default:
                return <QuestionMark />;
        }

    }, [pointType]);


    const locationImageUrl = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + (location ? location.picture : process.env.REACT_APP_DEFAULT_IMAGE);

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const deleteLocation = () => {
        API.locations.deleteLocation(token, location._id).then(res => {
            if (res.status === 200) {
                onDelete(res.data);
                return;
            }
        }).catch(error => {
            console.error("Error deleting location:", error);
            setAlert({ open: true, message: error.data, severity: "error" });
            setSubmittedFlag(false);
        })
    }

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.message} />
        <ConfirmationDialog
            open={openDeleteDialog}
            title={!location ? null : <span><FormattedMessage id="CONFIRM_DELETE_LOCATION" /> {location.name}</span>}
            handleCancel={() => setOpenDeleteDialog(false)}
            handleCustomButton={() => deleteLocation()}
            customButtonTitle={<FormattedMessage id="DELETE" />}
            customButtonColor='error'
        />
        <Dialog
            open={open}
            fullScreen={fullScreen}
            fullWidth maxWidth='sm'
            aria-labelledby="new-location-form"
            scroll="paper"
        >
            <DialogTitle>
                {!location ? <FormattedMessage id="ADD_LOCATION_GROUP" /> :
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <FormattedMessage id="UPDATE" /> {location.name}
                        <Tooltip key="deleteLocationTooltip" title={<FormattedMessage id="DELETE_LOCATION" />} placement="top-start" arrow>
                            <IconButton key="deleteLocationTooltip" onClick={() => setOpenDeleteDialog(true)} ><DeleteOutlined color="error" /></IconButton>
                        </Tooltip>
                    </Box>}
                <Typography variant='caption' sx={{ display: "block", color: "warning.main" }}><FormattedMessage id="LOCATION_SUBTITLE" /></Typography>
            </DialogTitle>

            <Divider />

            <DialogContent sx={fullScreen ? null : { px: 8 }}>
                <Grid container direction={fullScreen ? "column-reverse" : "row"} spacing={fullScreen ? 0 : 1}>
                    <Grid item xl={6} lg={6} md={6}>
                        <Stack direction="row" spacing={1}>
                            <Avatar sx={{ bgcolor: "primary.main", mt: 2 }}>{renderPinIcon()}</Avatar>
                            <Autocomplete sx={{ width: '100%' }} size='small' disableClearable
                                value={pointType}
                                onChange={(event, value) => setPointType(value)}
                                getOptionLabel={(opt) => intl.formatMessage({ id: "ATTRACTION." + attractionTypes.find(type => type.key === opt).value })}
                                options={attractionTypes.map(el => el.key)}
                                renderInput={(params) => <TextField margin="normal" {...params} label={<FormattedMessage id="ATTRACTION.TYPE" />} />}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6}>
                        <TextField required autoFocus size="small" variant="outlined" name="name" fullWidth margin="normal"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            label={<span><FormattedMessage id="NAME" /></span>}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ height: '200px', mt: 2 }}>
                    <MapComponent className="pin-input" zoom={16} position={pinnedPosition} disableShapes geoLocation={(data) => setPosition(data)}>
                        <MapElementNew
                            key="map-elements"
                            markerSource={markerSource.current}
                            positionInput={position}
                            positionChange={setPosition}
                        />
                    </MapComponent>
                </Box>
                <Stack direction={smallScreen ? "column" : "row"} spacing={1} sx={{ mt: 0.5, mb: 1 }}>
                    <CustomInputField value={position[0]} onChange={(e) => setPosition([e.target.value, position[1]])} messageId="LATITUDE">
                        <SwapHorizontalCircleOutlined />
                    </CustomInputField>
                    <CustomInputField value={position[1]} onChange={(e) => setPosition([position[0], e.target.value])} messageId="LONGITUDE">
                        <SwapVerticalCircleOutlined />
                    </CustomInputField>
                </Stack>

                <Autocomplete multiple disableCloseOnSelect sx={{ width: '100%' }} size="small" disableClearable
                    value={selectedType}
                    onChange={(event, value) => setSelectedType(value)}
                    getOptionLabel={(opt) => intl.formatMessage({ id: "TOURISM." + tourismTypes.find(type => type.key === opt).value })}
                    options={tourismTypes.map(el => el.key)}
                    renderInput={(params) => <TextField helperText={selectedType?.length < 2 && <FormattedMessage id="MULTIPLE_CHOICE" />} margin="normal" {...params} label={<><FormattedMessage id="TOURISM.TYPE" /> *</>} />}
                />

                <Autocomplete multiple disableCloseOnSelect sx={{ width: '100%' }} size="small" disableClearable
                    value={selectedAccessibility}
                    onChange={(event, value) => setSelectedAccessibility(value)}
                    getOptionLabel={(opt) => intl.formatMessage({ id: "ACCESSIBILITY_OPTIONS." + accessibilityOptions.find(type => type.key === opt).value })}
                    options={accessibilityOptions.map(el => el.key)}
                    renderInput={(params) => <TextField helperText={selectedAccessibility?.length < 2 && <FormattedMessage id="MULTIPLE_CHOICE" />} margin="normal" {...params} label={<><FormattedMessage id="ACCESSIBILITY_OPTIONS" /> *</>} />
                    }
                />

                <TextField required size="small" variant="outlined" multiline rows="5" name="description" fullWidth sx={{ my: 2 }}
                    label={<span><FormattedMessage id="DESCRIPTION" /> (<FormattedMessage id={"LANGUAGE." + intl.locale} />)</span>}
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                />

                <TextField size="small" variant="outlined" multiline rows="5" name="additionalInfo" fullWidth sx={{ my: 2 }}
                    label={<span><FormattedMessage id="ADDITIONAL_INFO" /> (<FormattedMessage id={"LANGUAGE." + intl.locale} />)</span>}
                    value={additionalInfo}
                    onChange={(event) => setAdditionalInfo(event.target.value)}
                />

                {(viewMeasurementsPermission || viewSamplePermission || createSamplePermission) && <Box sx={{ py: 1 }}>
                    <FormControl component="fieldset">
                        <Box>
                            <FormLabel><FormattedMessage id="RESOURCE_TYPES" /></FormLabel>
                            {Object.entries(expandedResourceGroups).every(([key, value]) => value) ?
                                <Button sx={{ mx: 2 }} variant="text" onClick={() => handleExpandResourceGroupClick("all", false)}><FormattedMessage id="COLLAPSE" /></Button> :
                                <Button sx={{ mx: 2 }} variant="text" onClick={() => handleExpandResourceGroupClick("all", true)}><FormattedMessage id="EXPAND" /></Button>
                            }
                        </Box>
                        {resourceTypeGroups.map(rtGroup =>
                            <Box sx={{ mb: '4px' }} key={rtGroup._id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Object.entries(resources).filter(([key, value]) => value.group === rtGroup.name).every(([key, value]) => value.checked)}
                                            indeterminate={Object.entries(resources).filter(([key, value]) => value.group === rtGroup.name).some(([key, value]) => value.checked)
                                                && Object.entries(resources).filter(([key, value]) => value.group === rtGroup.name).some(([key, value]) => !value.checked)}
                                            onChange={(e) => handleResourcesGroupCheck(e.target.checked, rtGroup.name)}
                                        />
                                    }
                                    label={<FormattedMessage id={"RESOURCE_TYPE.GROUP." + rtGroup.name} />}
                                />
                                {expandedResourceGroups[rtGroup.name] ?
                                    <ExpandLessOutlined sx={{ position: 'relative', top: '8px' }} onClick={() => handleExpandResourceGroupClick(rtGroup.name)} /> :
                                    <ExpandMoreOutlined sx={{ position: 'relative', top: '8px' }} onClick={() => handleExpandResourceGroupClick(rtGroup.name)} />
                                }
                                <Collapse in={expandedResourceGroups[rtGroup.name]} timeout="auto" unmountOnExit>
                                    <FormGroup sx={{ ml: 2 }}>
                                        {resourceTypesCheckboxes.filter(el => el.props.name === rtGroup.name)}
                                    </FormGroup>
                                </Collapse>
                            </Box>
                        )}
                    </FormControl>
                </Box>}

                <PictureUploadButton
                    defaultImage={location ? { name: location.name, url: locationImageUrl, type: "image" } : undefined}
                    setFiles={setImage}
                    single
                />

                {!isAlbumUploaded ? <Button
                    sx={{ mx: 'auto', my: 1 }}
                    size="small"
                    startIcon={<AddPhotoAlternate />}
                    name='newAlbum' key="newAlbum"
                    onClick={handleOpenAlbumDialog}
                >
                    <FormattedMessage id="ADD_ALBUM" />
                </Button> : <Card sx={{ mt: 2 }}>
                    <CardHeader title={<Typography variant="h6"><FormattedMessage id="ALBUM_DETAILS" /></Typography>}
                        subheader={<Alert sx={{ mt: 1 }} severity="success"> <FormattedMessage id="ALBUM_SAVED" /></Alert>} />
                    <CardContent>
                        <AlbumDetails notes={newAlbum.note} pictures={locationFormPictures} locationFormFlag={true} addedBy={username} />
                    </CardContent>
                    <CardActions>
                        <Button color="error" onClick={() => setIsAlbumUploaded(false)}><FormattedMessage id="ALBUM_DELETE" /></Button>
                    </CardActions>
                </Card>}
                <AddImagesForm location={tempLocation} open={isOpenAlbumDialog} onClose={handleCloseAlbumDialog} locationFormNewAlbum={newAlbum} setLocationFormNewAlbum={setNewAlbum} setUploadedFlag={setIsAlbumUploaded} setLocationFormResizedItems={setLocationFormResizedItems} setLocationFormImageDescriptions={setAlbumImageDescriptions} setLocationFormPictures={setLocationFormPictures} />
            </DialogContent>
            <Divider />
            <DialogActions sx={{ mx: 2 }}>
                <Button color="warning" onClick={onClose}><FormattedMessage id="CANCEL" /></Button>
                <Button disabled={!((location ? reviewerPermission : creatorPermission) || adminCreatorPermission) || disabledSubmitButton || submittedFlag || descLimitOverFlow} sx={(!(location || !reviewerPermission || adminCreatorPermission) || updateStagingLocation) ? { display: 'none' } : null} onClick={onSubmitHandler}><FormattedMessage id={"SUBMIT"} /></Button>
                <Button disabled={disabledSubmitButton || descLimitOverFlow} sx={(location || !reviewerPermission || adminCreatorPermission || submittedFlag) ? { display: 'none' } : null} onClick={() => onSubmitHandler(false)}><FormattedMessage id="SUBMIT_AND_REVIEW" /></Button>
                <Button variant="outlined" disabled={!name.length || submittedFlag || descLimitOverFlow} sx={(reviewerPermission || adminCreatorPermission || noSaveOption) ? { display: 'none' } : null} onClick={() => onSubmitHandler(true, true)} startIcon={<SaveOutlined />}><FormattedMessage id="SAVE" /></Button>
            </DialogActions>
        </Dialog >
    </>
}