import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { userSelector, permissionSelector } from "redux/userSlice";
import { selectMeasurePoints } from "redux/locationsSlice";
import { selectSeverityTypesConf } from "redux/configurationSlice";
import { useNavigate } from 'react-router-dom';
import { AlarmProfileRules, AlertSnackbar } from 'components';
import { Stack, Autocomplete, FormLabel, TextField, DialogActions, Button, Typography, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { AddOutlined, LabelImportantOutlined } from '@mui/icons-material';
import API from 'api';


function AlarmProfileForm(props) {
    let { alarm, onClose, onSubmit, updateAlarmsData, templateFlag } = props;
    const locations = useSelector(selectMeasurePoints);
    const severityTypes = useSelector(selectSeverityTypesConf)
    const [alarmName, setAlarmName] = useState((alarm && alarm.name) || "")
    const [locationId, setLocationId] = useState((alarm && alarm.locationId) || null)
    const [location, setLocation] = useState(locationId ? locations.find(loc => loc._id === locationId) : null)
    const [alarmRules, setAlarmRules] = useState((alarm && alarm.rules && {
        ...alarm.rules
    }) || {});
    const [usedTypes, setUsedTypes] = useState(Object.keys(alarmRules));
    const [newRule, setNewRule] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const intl = useIntl()
    const navigate = useNavigate();
    const { token } = useSelector(userSelector);
    const deleteRights = useSelector((state) => permissionSelector(state, 'delete-alarmProfiles'));
    const [openDialog, setOpenDialog] = useState(false);
    const [buttonDelete, setButtonDelete] = useState(false);
    const [filteredLocations, setFilteredLocations] = useState(locations);
    const [resourceFlag, setResourceFlag] = useState(false);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);

    const updateAlarm = useCallback((data, type, rule, filtIndex, condIndex) => {
        switch (type) {
            case 'name':
                setAlarmName(data);
                return;
            case 'locationid':
                setLocationId(data);
                return;
            case 'rules-edit':
                setAlarmRules({
                    ...alarmRules,
                    [rule]: {
                        ...alarmRules[rule],
                        notificationMethod: data
                    }
                })
                return;
            case 'rule-severity-edit':
                const editedSeverityRules = {
                    ...alarmRules,
                    [data]: alarmRules[rule],
                    [rule]: undefined
                }

                delete editedSeverityRules[rule]
                setAlarmRules(editedSeverityRules)

                let newUsedTypes = usedTypes
                if (newUsedTypes.includes(data)) {
                    newUsedTypes.splice(newUsedTypes.indexOf(data), 1)
                }

                newUsedTypes.splice(newUsedTypes.indexOf(rule), 1, data)
                setUsedTypes(newUsedTypes)
                return;
            case 'rules-add':
                setAlarmRules({
                    ...alarmRules,
                    [data]: {
                        type: "SIMPLE",
                        details: "",
                        filters: [],
                        notificationMethod: [],
                    }
                });
                setUsedTypes([...usedTypes, data]);
                return;
            case 'rules-delete':
                const newRules = {
                    ...alarmRules,
                    [data]: undefined
                };
                delete newRules[data];
                setAlarmRules(newRules);
                setUsedTypes(usedTypes.filter(el => el !== data));
                return;
            case 'message':
                setAlarmRules({
                    ...alarmRules,
                    [rule]: {
                        ...alarmRules[rule],
                        details: data
                    }
                })
                return;
            case 'filters':
                const ruleFilters = [...alarmRules[rule].filters];

                if (data === undefined) ruleFilters.splice(filtIndex, 1);
                else ruleFilters.splice(filtIndex, 1, data);
                setAlarmRules({
                    ...alarmRules,
                    [rule]: {
                        ...alarmRules[rule],
                        filters: ruleFilters
                    }
                });
                break;
            case 'condition':
                const conditions = [...alarmRules[rule].filters[filtIndex].conditions];
                const filters = [...alarmRules[rule].filters];

                if (data === undefined) conditions.splice(condIndex, 1);
                else conditions.splice(condIndex, 1, data);

                filters[filtIndex].conditions = conditions;
                setAlarmRules({
                    ...alarmRules,
                    [rule]: {
                        ...alarmRules[rule],
                        filters
                    }
                });
                break;
            default:
                setAlarmName(alarm.name);
                setLocationId(alarm.locationId);
                setAlarmRules(alarm.rules);
                return;
        }
    }, [alarm, alarmRules, usedTypes]);

    useEffect(() => {
        let resourceTypesList = [];
        const foundLocation = locations.find(loc => loc._id === locationId)
        Object.keys(alarmRules).map((rule) => (
            alarmRules[rule].filters.map((filter, index) => (
                alarmRules[rule].filters[index].conditions.map((cond) => resourceTypesList.push(cond.resourceTypeKey)
                )))
        ))
        resourceTypesList = [...new Set(resourceTypesList)]
        if (foundLocation && resourceTypesList.some(resType => !foundLocation.resourceTypes.includes(resType) && !foundLocation.serviceResourceTypes.includes(resType))) {
            setResourceFlag(true);
            setAlert({ open: resourceFlag, messageId: "LOCATION.MISSING.RESOURCES", severity: "warning" });
        }
        else {
            setResourceFlag(false);
            setAlert({ open: false, messageId: "LOCATION.MISSING.RESOURCES", severity: "warning" });
        }

        setFilteredLocations(locations.filter(loc => resourceTypesList.every(resType => loc.resourceTypes.includes(resType) || loc.serviceResourceTypes.includes(resType))))
    }, [alarmRules, locationId, locations, resourceFlag])

    useEffect(() => {
        if (alarmName?.trim() === "" || !locationId) setDisabledSubmitButton(true);
        else setDisabledSubmitButton(false);
    }, [alarmName, locationId])

    const newRuleHandler = () => {
        setNewRule(true);
    }

    const onSubmitHandler = () => {
        const newAlarmProfileObject = templateFlag ? {
            name: alarmName,
            locationId: locationId,
            rules: alarmRules,
        } : {
            ...alarm,
            name: alarmName,
            locationId: locationId,
            rules: alarmRules
        }
        if (onSubmit) {
            if (newAlarmProfileObject.name === "" || newAlarmProfileObject.locationId === "") setAlert({ open: true, messageId: "ALARM.PROFILE.REQUIRED_FIELD", severity: "error" });
            else {
                API.alarmProfiles.postAlarmProfile(token, newAlarmProfileObject).then(item => {
                    if (item && item.data) {
                        onSubmit();
                    }
                }).catch(error => {
                    setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                    console.error('Error creating alarm profile:', error)
                })
                if (templateFlag) navigate('/alarms/profiles/');
            }
        }
        else {
            API.alarmProfiles.updateAlarmProfile(token, alarm._id, newAlarmProfileObject).then(item => {
                if (item && item.data && updateAlarmsData) {
                    updateAlarmsData(item.data);
                    navigate('/alarms/profiles/' + newAlarmProfileObject._id);
                }
            }).catch(error => {
                setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                console.error('Error updating alarm profile:', error)
            })
        }
    }
    const onCancelHandler = () => {
        if (alarm && alarm._id && !templateFlag) {
            API.alarmProfiles.getAlarmProfilebyId(token, alarm._id).then(item => {
                if (item && item.data && updateAlarmsData) {
                    updateAlarmsData(item.data)
                    navigate('/alarms/profiles/' + alarm._id);
                }
            }).catch(error => {
                setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                console.error('Error fetching alarm profile:', error)
            })
        }
        else if (onClose) onClose();
        else navigate('/alarms/profiles');
    }

    const onDeleteHandler = () => {
        if (alarm && alarm._id) {
            API.alarmProfiles.deleteAlarmProfile(token, alarm._id).then(response => {
                if (response.status === 200) {
                    setAlert({ open: true, messageId: "ALARM.PROFILE.DELETED", severity: "success" });
                    setButtonDelete(true)
                    setOpenDialog(false)
                }
            }).catch(error => {
                setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                console.error('Error deleting alarm profile:', error)
            })
        }
    }

    useEffect(() => {
        if (buttonDelete && !alert.open) {
            navigate('/alarms/profiles')
        }
    }, [buttonDelete, navigate, alert.open])


    return <>
        <AlertSnackbar open={resourceFlag || alert.open} onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} messageId={alert.messageId} autoHide={alert.autoHide} />
        {alarm && deleteRights ? <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>
                <FormattedMessage id="ALARM.PROFILE.DELETE" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ typography: 'body2' }} id="alert-dialog-description">
                    <FormattedMessage id="NAME" />: <FormLabel sx={{ color: 'primary.main', typography: 'body1' }}>{alarmName}</FormLabel> <br></br>
                    <FormattedMessage id="COUNT_RULES" /> <FormLabel sx={{ color: 'primary.main', typography: 'body1' }}>{Object.keys(alarmRules).length}</FormLabel>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenDialog(false)} autoFocus><FormattedMessage id="CANCEL" /></Button>
                <Button color="error" onClick={() => onDeleteHandler()}><FormattedMessage id="DELETE" /></Button>
            </DialogActions>
        </Dialog> : null}
        <Stack spacing={1} direction={'column'} sx={{ px: 1 }}>
            <FormLabel sx={{ color: 'primary.main' }}><FormattedMessage id="NAME" /></FormLabel>
            <TextField
                fullWidth
                variant="outlined"
                id="NAME"
                placeholder={intl.formatMessage({ id: "NAME" })}
                type="text"
                size="small"
                value={alarmName}
                onChange={(e) => updateAlarm(e.target.value, 'name')}
            />

            <FormLabel sx={{ color: 'primary.main', pt: 1.5 }}><FormattedMessage id="LOCATION" /></FormLabel>
            <Autocomplete
                disabled={!!(alarm && alarm.locationId && !templateFlag)}
                size="small"
                id="locations"
                options={filteredLocations}
                getOptionLabel={(location) => location.name}
                value={location}
                onChange={(e, location) => {
                    updateAlarm(location?._id, 'locationid');
                    setLocation(location);
                }}
                renderInput={(params) => (
                    <TextField {...params} key={locations.map(location => location.name)}
                        helperText={templateFlag ? intl.formatMessage({ id: "LOCATION.RESOURCE_TYPES_FILTER" }) : ""}
                    />
                )}
                fullWidth
            />
            <Stack key={JSON.stringify(alarmRules)} direction="column" alignItems="start" spacing={2}>
                <FormLabel sx={{ color: 'primary.main' }}><FormattedMessage id="RULES" /></FormLabel>
                {usedTypes.map((rule) => alarmRules[rule] !== undefined && <AlarmProfileRules key={rule} alarmRule={alarmRules[rule]} rule={rule} updateAlarm={updateAlarm} locationId={locationId} severityTypes={severityTypes} usedTypes={usedTypes} />)}
                {!(usedTypes.length === severityTypes.length) && (!newRule ? <Button onClick={newRuleHandler} startIcon={<AddOutlined />}>
                    <FormattedMessage id="ADD.RULE" />
                </Button> : <Stack sx={{ alignItems: 'center', width: '100%' }} spacing={0.5} direction="row">
                    <Typography variant="body2" color='primary.main'><FormattedMessage id="ADD.RULE" /></Typography>
                    <TextField
                        sx={{ textAlign: 'center', minWidth: '20%' }}
                        select
                        size="small"
                        value={""}
                        label={<FormattedMessage id="SELECT_SEVERITY" />}
                        onChange={(e) => {
                            const type = e.target.value
                            setNewRule(false);
                            setUsedTypes([...usedTypes, type.value]);
                            updateAlarm(type.value, 'rules-add');
                        }}
                    >
                        {severityTypes.filter(el => !usedTypes.includes(el.value)).map((sevType, index) => (
                            <MenuItem key={sevType.value + index} value={sevType}>
                                <LabelImportantOutlined
                                    name={'severity-' + sevType.value}
                                    className={'severity-' + sevType.value}
                                    sx={(theme) => ({ width: theme.typography.fontSize * 1, height: theme.typography.fontSize * 1, mr: 0.7 })}
                                />
                                <FormattedMessage id={"SEVERITY." + sevType.value} />
                            </MenuItem>
                        ))}
                    </TextField>
                </Stack>)}
            </Stack>
            <DialogActions>
                <Button color="warning" onClick={onCancelHandler}><FormattedMessage id={"CANCEL"} /></Button>
                {!templateFlag && deleteRights && alarm && <Button color="error" onClick={() => setOpenDialog(true)}><FormattedMessage id={"DELETE"} /></Button>}
                <Button disabled={resourceFlag || disabledSubmitButton} onClick={onSubmitHandler}><FormattedMessage id={templateFlag ? "ADD" : "SUBMIT"} /></Button>
            </DialogActions>
        </Stack >
    </>

}

export default AlarmProfileForm;