import { Alert, Snackbar, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export default function AlertSnackbar(props) {
    const { noAnchor, open, onClose, autoHide, severity, message, messageId } = props;
    const autoHideDuration = autoHide === "none" ? Number.MAX_VALUE : Number(autoHide);
    const displayMessage = (() => {
        if (message === "Unauthorized" || messageId === "Unauthorized") return "ERROR.UNAUTHORIZED";
        else if (message?.id || message || messageId?.id || messageId) return message?.id || message || messageId?.id || messageId;
        else return "ERROR";
    })();

    return <Snackbar
        anchorOrigin={!noAnchor ? { vertical: 'top', horizontal: 'center' } : undefined}
        open={open}
        onClose={onClose}
        key={"alert-message-" + displayMessage}
        autoHideDuration={autoHideDuration || 5000}
    >
        <Alert severity={severity} sx={{ width: '100%' }} onClose={onClose}>
            <Typography>
                <FormattedMessage id={displayMessage} />
            </Typography>
        </Alert>
    </Snackbar>
}