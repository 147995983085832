import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { selectResourcesTypesConf } from "redux/configurationSlice";
import { MeasurementReportingFilters, MeasurementsTableContainer, MeasurementsTrendGraph, OWAttribution } from 'components';
import { Box, Grid, Stack } from '@mui/material';

export default function MeasurementsView(props) {
    const { location } = props
    const resourceTypesConfig = useSelector(selectResourcesTypesConf);
    const [locations, setLocations] = useState(location ? [location] : []);
    const [tablesView, setTablesView] = useState(!location);
    const [fullRowView, setFullRowView] = useState(!!location);
    const [measurementTypes, setMeasurementTypes] = useState([]);
    const [selectedMeasurementType, setSelectedType] = useState(null);
    const [sampleTypes, setSampleTypes] = useState([]);
    const [aggregation, setAggregation] = useState({ group: 'NO_GROUP', time: null });

    const filterHandler = useCallback((data, type) => {
        switch (type) {
            case 'locations':
                setLocations(data);
                return;
            case 'tables':
                setTablesView(!tablesView);
                return;
            case 'fullRow':
                setFullRowView(!fullRowView);
                return;
            case 'samples':
                setSampleTypes(data);
                setSelectedType(null);
                return;
            case 'measurements':
                setMeasurementTypes(data);
                setSelectedType(null);
                return;
            case 'aggregation':
                setAggregation(data);
                return;
            default:
                setSampleTypes([]);
                setMeasurementTypes([]);
                setSelectedType(null);
                setAggregation({ group: 'NO_GROUP', time: null });
                return;
        }
    }, [setLocations, setTablesView, tablesView, setFullRowView, fullRowView, setSampleTypes, setMeasurementTypes, setAggregation, setSelectedType]);

    const renderCharts = useCallback(() => {

        const attribution = () => {
            if (!sampleTypes.length && !measurementTypes.length && locations.flatMap(el => el.serviceResourceTypes).length) return true;
            else if (measurementTypes.length && measurementTypes.filter(types => types.key < 2000).length) return true;
            else if (sampleTypes.length && sampleTypes.filter(types => types.value === "SERVICE").length) return true;
            else return false;
        };

        return <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
            <MeasurementReportingFilters
                multiLocation
                filterHandler={filterHandler}
                tablesView={tablesView}
                fullRowView={fullRowView}
                selectedLocations={locations}
                selectedType={selectedMeasurementType}
                sampleTypesSelected={sampleTypes}
                measurementTypesSelected={measurementTypes}
                types={resourceTypesConfig}
                aggregation={aggregation}
                attribution={attribution() ? <OWAttribution /> : undefined}
            />
            <Box sx={{ ml: -2 }}>
                <Grid container spacing={2} direction="row">
                    {locations.map(location => {

                        const itemWidth = fullRowView ? 12 : 6;
                        return !tablesView ?
                            <Grid item xs={12} sm={12} md={12} lg={itemWidth} xl={itemWidth} key={location.name} >
                                <MeasurementsTrendGraph
                                    cardLink
                                    title={location.name}
                                    locationId={location._id}
                                    resourceTypes={measurementTypes.map(types => types.key)}
                                    sampleTypes={sampleTypes.map(types => types.key)}
                                    aggregation={aggregation}
                                />
                            </Grid> :
                            <Grid item xs={12} sm={12} md={12} lg={itemWidth} xl={itemWidth} key={location._id}>
                                <MeasurementsTableContainer
                                    key={location._id}
                                    locationId={location._id}
                                    resourceTypes={measurementTypes.map(types => types.key)}
                                    sampleTypes={sampleTypes.map(types => types.key)}
                                    aggregation={aggregation}
                                />
                            </Grid>
                    })}
                </Grid>
            </Box>
        </Stack>
    },
        [locations, filterHandler, resourceTypesConfig, selectedMeasurementType, measurementTypes, sampleTypes, aggregation, tablesView, fullRowView]);

    return renderCharts();
}