import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { NewNoteDialog, NoteDetails, CustomDataGrid, SideDrawer, AlertSnackbar } from 'components';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { useSelector } from 'react-redux';
import { formatDateLocale } from 'utils';
import { selectMeasurePoints } from 'redux/locationsSlice';
import { selectLocationNotesConf, selectNotificationMethodConf } from 'redux/configurationSlice';
import API from 'api';

import { Autocomplete, Box, Button, Card, CardActions, CardContent, CardHeader, TextField, Stack, useMediaQuery } from '@mui/material';

export default function NotesView(props) {
    const { locationId } = props;
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const user = useSelector(userSelector);
    const noteTypesConfig = useSelector(selectLocationNotesConf);
    const notificationTypesConfig = useSelector(selectNotificationMethodConf);
    const locations = useSelector(selectMeasurePoints);
    const noteCreatorRights = useSelector((state) => permissionSelector(state, 'create-notes'));
    const noteArchiverRights = useSelector((state) => permissionSelector(state, 'update-notes'));
    const [open, setOpen] = useState(false);
    const [openDrawer, setDrawer] = useState(false);
    const [details, setDetails] = useState({});
    const [detailsNote, setDetailsNote] = useState(false);
    const { token } = useSelector(userSelector);
    const [notesData, setNotesData] = useState([]);
    const [noteObject, setNoteObject] = useState(null);
    const [noteUnarchiveObject, setNoteUnarchiveObject] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(locationId ? locations.find(loc => loc._id === locationId) : null);
    const [inputValue, setInputValue] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [archivedView, setArchivedView] = useState(false);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const archiveNote = () => {
        setNoteObject({
            noteIdArray: selectedRows.map(el => { return el.id })
        });
    }
    const unarchiveNote = () => {
        setNoteUnarchiveObject({
            noteIdArray: selectedRows.map(el => { return el.id })
        });
    }

    useEffect(() => {
        if (noteObject) API.notes.archiveNote(user.token, noteObject);
    }, [noteObject, user.token]);
    useEffect(() => {
        if (noteUnarchiveObject) API.notes.unarchiveNote(user.token, noteUnarchiveObject);
    }, [noteUnarchiveObject, user.token]);

    useEffect(() => {
        API.notes.getAllNotes(token, selectedLocation ? [selectedLocation._id] : undefined).then(items => {
            if (items.data) {
                const data = items.data.map(collection => {
                    const notificationTypeValues = collection.notificationMethod.map(el => notificationTypesConfig.find(type => type.key === el).value);
                    const noteTypeValue = noteTypesConfig.find(type => type.key === collection.type).value;
                    return {
                        id: collection._id,
                        username: collection.user,
                        location: locations.find(el => el._id === collection.locationId).name,
                        locationId: collection.locationId,
                        date: formatDateLocale(collection.timestamp),
                        notificationTypes: notificationTypeValues,
                        notificationTypesLocale: notificationTypeValues.map(el => intl.formatMessage({ id: "NOTIFICATION_OPTION." + el })),
                        noteType: noteTypeValue,
                        noteTypeLocale: intl.formatMessage({ id: "NOTE_OPTION." + noteTypeValue }),
                        title: collection.title,
                        text: collection.content,
                        groupLevel: collection.groupLevel,
                        archived: collection.archived,
                        sendFailed: collection.sendFailed
                    }
                })
                setNotesData(data);
            }
            else setNotesData([]);
        }).catch(error => {
            setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
            setNotesData([]);
        })
    }, [token, selectedLocation, locations, notificationTypesConfig, noteTypesConfig, noteObject, noteUnarchiveObject, open, openDrawer, intl]);

    const handleData = ({ row }, event) => {
        setDetailsNote(row)
        setDrawer(true);
    }

    useEffect(() => {
        if (detailsNote) {
            try {
                setDetails({
                    title: <FormattedMessage id='NOTE_DETAILS' />,
                    subtitle: detailsNote?.date,
                    display: <NoteDetails data={detailsNote} toggleDrawer={() => setDrawer(false)} />
                })
            } catch (err) {
                console.error(err);
                setDetails({
                    title: <FormattedMessage id='SAMPLING_LOG_REPORT.ERROR' />,
                    subtitle: '',
                    display: <div />
                });
            }
        }
    }, [detailsNote])

    const renderTable = useCallback(() => {
        const columns = [
            {
                field: 'username',
                headerName: intl.formatMessage({ id: 'USERNAME' }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 100 }),
            },
            {
                field: 'location',
                headerName: intl.formatMessage({ id: 'LOCATION' }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
            {
                field: 'title',
                headerName: intl.formatMessage({ id: 'NOTE_TITLE' }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
            {
                field: 'date',
                headerName: intl.formatMessage({ id: 'NOTE_DATE' }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
            {
                field: 'noteTypeLocale',
                headerName: intl.formatMessage({ id: 'NOTE_TYPE' }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 100 }),
            }
        ];
        return <CustomDataGrid
            enableExport
            columns={columns}
            setSelectedRows={setSelectedRows}
            handleData={handleData}
            rows={notesData.filter(el => archivedView ? el.archived === true : el.archived === false)}
        />;

    }, [intl, notesData, archivedView, smallScreen]);

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <Card sx={{ width: '100%' }}>
            <CardHeader
                title={<FormattedMessage id="NOTES" />}
                action={
                    <Stack direction={smallScreen ? "column" : "row"} spacing={1} sx={{ mt: smallScreen ? 2 : 0 }}>
                        <Autocomplete
                            id="controllable-states-demo"
                            size="small"
                            freeSolo
                            sx={{ width: smallScreen ? '100%' : 300 }}
                            value={selectedLocation}
                            onChange={(event, value) => {
                                setSelectedLocation(value)
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            getOptionLabel={(location) => location.name}
                            options={locations}
                            renderInput={(params) => <TextField {...params} key={selectedLocation} label={<FormattedMessage id="ALL_LOCATIONS" />} />}
                        />
                        <div>
                            {noteCreatorRights ? <Button onClick={() => setOpen(true)}> <FormattedMessage id="ADD_NOTE" /> </Button> : null}
                            <Button onClick={() => setArchivedView(archivedView ? false : true)}> <FormattedMessage id={archivedView ? "SHOW_UNARCHIVED" : "SHOW_ARCHIVED"} /> </Button>
                        </div>
                    </Stack>
                }
                sx={{ flexWrap: "wrap" }}
            />
            <CardContent>
                {renderTable()}
            </CardContent>

            <CardActions sx={{ pl: 2 }}>
                <Box>
                    {noteArchiverRights ? <Button disabled={selectedRows.length < 1} onClick={() => archivedView ? unarchiveNote() : archiveNote()} variant="text"><FormattedMessage id={archivedView ? "UNARCHIVE" : "ARCHIVE"} /></Button> : null}
                </Box>
            </CardActions>
            <NewNoteDialog key={selectedLocation} open={open} handleDialogClose={() => setOpen(false)} locationId={selectedLocation && selectedLocation._id} />
            <SideDrawer state={details} open={openDrawer} toggleDrawer={() => setDrawer(false)} />
        </Card>
    </>;
}