import React, { useCallback, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { FormattedMessage } from 'react-intl';
import { Navigation } from "swiper";
import { AspectRatio } from 'react-aspect-ratio';
import { useMediaQuery, Stack, Typography, Dialog, Fab, Tooltip, TextField } from '@mui/material';
import { DeleteOutlined, /*ZoomIn,*/ Close } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { permissionSelector } from 'redux/userSlice';
import { AlertSnackbar } from 'components';
import { isImage } from "utils"
import ReactPlayer from 'react-player'
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";

const isImageType = (image) => {
  if (image?.type) return image.type === "image";
  else return isImage(image.name);
}

const ImgOverlay = (props) => {
  const { show, /*onImageClick,*/ onImageDelete, /*isVideo*/ } = props;
  const deleteAlbumFilePermission = useSelector((state) => permissionSelector(state, 'delete-album-file'));

  //const theme = useTheme();
  const xsScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const smScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  // if  return null;
  if (!deleteAlbumFilePermission || !show || (xsScreen || smScreen)) return null;

  return (
    <div className='imgDetails' style={{ pointerEvents: 'none' }}>
      <div style={{ cursor: 'pointer', pointerEvents: 'all' }} className='deleteIcon' onClick={() => onImageDelete()}>
        <DeleteOutlined fontSize="medium" color="error" style={{ marginTop: '10px' }} />
      </div>
    </div>
  )
}

const ImgOverlayMobile = (props) => {
  const { onImageDelete, open } = props;

  return (
    <Tooltip aria-disabled={open} title={<FormattedMessage id="FILE_DELETE" />} placement="left">
      <Fab size="small" sx={{ position: 'absolute', top: 4, right: 4, display: open ? 'none' : 'default' }} color="error" onClick={() => onImageDelete()}>
        <DeleteOutlined fontSize="small" />
      </Fab>
    </Tooltip>
  )
}

const PictureGallery = (props) => {
  const { pictures, maxWidth, onDelete = false, showName, setFileDelete, previewMode, imageDesc, onDescChange, locationFormFlag } = props
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ open: false });
  const [hover, setHover] = useState(false);
  const deleteAlbumFilePermission = useSelector((state) => permissionSelector(state, 'delete-album-file'));
  const playerRef = React.useRef(null);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const onAlertClose = () => setAlert({ ...alert, open: false });

  const onCloseDialog = () => {
    setOpen(false);
  };

  const renderGallery = useCallback(() => {
    const onImageClick = () => {
      setOpen(true);
    };

    const key = pictures && pictures.length ? pictures[0].name + pictures.length : null;

    if (key === null || key === undefined) {
      return null;
    }

    const matchImageDesc = (imageName) => {
      if (!imageDesc) return null;
      let [foundImage] = imageDesc.filter(el => el.name === imageName);
      return foundImage ? foundImage.desc : null;
    }

    return (
      <>
        <Swiper onActiveIndexChange={el => { el.navigation.nextEl.scrollIntoView({ behavior: 'smooth', block: 'center' }) }} style={maxWidth && { maxWidth: maxWidth }} key={key} navigation={true} modules={[Navigation]} className="mySwiper">
          {pictures.map((image, i) => {
            const imageProps = !deleteAlbumFilePermission || pictures.length === 1 ? {
              style: { cursor: 'zoom-in' },
              onClick: onImageClick
            } : null;
            return <SwiperSlide key={i}>
              <Stack direction="column" spacing={1}>
                <AspectRatio ratio="1">
                  {isImageType(image) ?
                    <div className='imgContainer' onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
                      {previewMode && pictures.length > 1 ?
                        <>
                          {!mobile && !locationFormFlag && <ImgOverlay show={hover && !open} onImageClick={() => onImageClick(image?.url)} onImageDelete={() => setFileDelete({ show: true, imageId: image?.id, callEffect: false })} />}
                          {mobile && !locationFormFlag && <ImgOverlayMobile open={open} onImageDelete={() => setFileDelete({ show: true, imageId: image?.id, callEffect: false })} />}
                        </>
                        : null
                      }
                      <img className='image' style={!open ? { cursor: 'zoom-in' } : null} src={image.url} alt={image.name} {...imageProps} onClick={() => onImageClick(image?.url)} />
                    </div>
                    :
                    <div className='imgContainer' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
                      {previewMode && pictures.length > 1 ?
                        <>
                          {!mobile && !locationFormFlag && <ImgOverlay isVideo={true} show={hover && !open} onImageDelete={() => setFileDelete({ show: true, imageId: image?.id, callEffect: false })} />}
                          {mobile && !locationFormFlag && <ImgOverlayMobile open={open} onImageDelete={() => setFileDelete({ show: true, imageId: image?.id, callEffect: false })} />}
                        </>
                        : null}
                      <ReactPlayer
                        className='image'
                        ref={playerRef}
                        url={image.url}
                        width="100%"
                        height="100%"
                        light={image.thumbnail}
                        controls
                      />
                    </div>
                  }
                </AspectRatio>
                {!!showName && <Typography variant="caption">{image.name}</Typography>}
                {typeof onDelete === 'function' && pictures.length > 1 && <Tooltip title={<FormattedMessage id="FILE_DELETE" />} placement="left">
                  <Fab size="small" sx={{ position: 'absolute', top: 4, right: 4 }} color="error" onClick={() => onDelete(i)}>
                    <DeleteOutlined fontSize="small" />
                  </Fab>
                </Tooltip>}
                {imageDesc && <TextField
                  label={<FormattedMessage id="MEDIA_DESCRIPTION" />}
                  onChange={(e) => onDescChange(e.target.value, image.name, i)}
                  value={matchImageDesc(image.name) || ''}
                  multiline
                  size='small'
                  fullWidth
                  sx={{ my: 3 }}
                >
                </TextField>}
              </Stack>
            </SwiperSlide>
          })}
        </Swiper>
      </>
    )

  }, [pictures, maxWidth, onDelete, showName, deleteAlbumFilePermission, hover, previewMode, imageDesc, onDescChange, setFileDelete, mobile, open, locationFormFlag]);

  return <>
    <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.message} />
    <Dialog open={open} onClose={onCloseDialog} maxWidth="md">
      <div style={{ position: 'absolute', right: 8 }}>
        <Fab sx={{ position: 'relative', top: 8 }} size="small" onClick={onCloseDialog}>
          <Close />
        </Fab>
      </div>
      {/* <AspectRatio ratio="1">
        <img src={imageView} alt="zoom" style={{ width: '100%', height: '100%' }} />
      </AspectRatio> */}
      <div style={{ overflow: 'auto' }} >
        {renderGallery()}
      </div>
    </Dialog>
    {renderGallery()}
  </>
}

export default PictureGallery;
