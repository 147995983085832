import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { Box, Button, Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { QueryStatsOutlined, BarChartOutlined, ShowChartOutlined, AssessmentOutlined, AddchartOutlined, NoteAddOutlined, AddPhotoAlternate } from '@mui/icons-material';
import { AddImagesForm } from 'components';
import { useSelector } from 'react-redux';
import { permissionSelector } from 'redux/userSlice';

const DateRangeSelector = (props) => {
    const { column, locationId, location, setRefreshData } = props;
    const navigate = useNavigate();
    const [anchorElRep, setAnchorElRep] = useState(null);
    const [anchorElSam, setAnchorElSam] = useState(null);
    const openRep = Boolean(anchorElRep);
    const openSam = Boolean(anchorElSam);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const samplerRights = useSelector((state) => permissionSelector(state, 'create-sample'));
    const createAlbumRights = useSelector((state) => permissionSelector(state, 'create-album'));

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };
    const handleClickSam = (event) => {
        setAnchorElSam(event.currentTarget);
    };
    const handleClickRep = (event) => {
        setAnchorElRep(event.currentTarget);
    };

    const handleCloseSam = () => {
        setAnchorElSam(null);
    };
    const handleCloseRep = () => {
        setAnchorElRep(null);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: column ? 'column' : 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}>
            <Box>
                <Button
                    size="small"
                    startIcon={<QueryStatsOutlined />}
                    onClick={handleClickRep}
                    name='reports' key="reports"
                >
                    <FormattedMessage id="REPORTS" />
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorElRep}
                    open={openRep}
                    onClose={handleCloseRep}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => navigate('/measurements?locationId=' + locationId)}>
                        <ListItemIcon>
                            <ShowChartOutlined fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="MEASUREMENTS" />
                        </ListItemText>
                    </MenuItem>

                    <MenuItem onClick={() => navigate('/samples?locationId=' + locationId)}>
                        <ListItemIcon>
                            <BarChartOutlined fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="SAMPLES" />
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </Box>
            {samplerRights && samplerRights.includes(locationId) && <Box>
                <Button
                    size="small"
                    startIcon={<AssessmentOutlined />}
                    onClick={handleClickSam}
                    name='reports' key="reports"
                >
                    <FormattedMessage id="SAMPLES" />
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorElSam}
                    open={openSam}
                    onClose={handleCloseSam}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => navigate('/sampleEntry?locationId=' + locationId)}>
                        <ListItemIcon>
                            <AddchartOutlined fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="ADD_SAMPLE" />
                        </ListItemText>
                    </MenuItem>

                    <MenuItem onClick={() => navigate('/sampleEntry/history?locationId=' + locationId)}>
                        <ListItemIcon>
                            <NoteAddOutlined fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="ADD_SAMPLE_FILE" />
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </Box>}
            {createAlbumRights && <Divider orientation={column ? "horizontal" : "vertical"} sx={{ my: column ? 1 : 0 }} flexItem />}
            {createAlbumRights && <Box>
                <Button
                    size="small"
                    startIcon={<AddPhotoAlternate />}
                    onClick={handleOpenDialog}
                    name='newAlbum' key="newAlbum"
                >
                    <FormattedMessage id="ADD_ALBUM" />
                </Button>
                <AddImagesForm location={location} open={isDialogOpen} onClose={handleCloseDialog} refreshData={setRefreshData} />
            </Box>}
        </Box>
    );
}

export default DateRangeSelector;